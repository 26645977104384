import * as ActionTypes from './ActionTypes';
import Axios from 'axios';
import {pt}  from '../../utils';
import { notification } from '../../utils';

export const setLoading = (payload) => ({
    type: ActionTypes.SET_PARTS_LOADING,
    payload
});
export const searchPartSuccess = (payload) => ({
    type: ActionTypes.GET_PARTS_SUCCESS,
    payload
});
export const searchPartError = (payload) => ({
    type: ActionTypes.GET_PARTS_ERROR,
    payload
});
export const searchPartSTR = (payload) => ({
    type: ActionTypes.PARTS_SEARCH_STR,
    payload
});
export const setAdvanceSearchData = (payload) => ({
    type: ActionTypes.PARTS_ADVANCE_SEARCH_DATA,
    payload
});
export const flushSearch = (payload) => ({
    type: ActionTypes.FLUSH_PARTS_SEARCH,
    payload
});
export const flushAdvanceSearch = (payload) => ({
    type: ActionTypes.FLUSH_PARTS_ADVANCE_SEARCH,
    payload
});


export const searchParts = (advanceData, payload, page, pageSize, order, isAvailabilityLimit, availabilityLimit) => {
    return dispatch => {
        dispatch(setLoading(true));
        let url =`${process.env.REACT_APP_API_URL}/api/parts-search?page=${page}&perPage=${pageSize}&orderBy=${order?.key}&order=${order?.order}`;
        if(isAvailabilityLimit){
         url =url+`&isAvailabilityLimit=${isAvailabilityLimit}&availabilityLimit=${availabilityLimit}`;
        }
        if(advanceData){
            url =`${url}&advance=true`
           Object.keys(advanceData).map(k=>{
               if(advanceData && advanceData[k] ){
                url =`${url}&${encodeURIComponent(k)}=${encodeURIComponent(advanceData[k])}`
                console.log({url});
               }
               
            })

        } else {
           url =`${url}&search=${payload}`
        }
        const token = localStorage.getItem('mAuth');
        Axios.get(url,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(res => {
                dispatch(setLoading(false));
                if (res.status == 200 && res.data ) {
                    dispatch(searchPartSuccess(res.data));
                } else {
                    notification('error',  'Oops!! something went wrong')
                    dispatch(searchPartError(res.data));
                }
            })
            .catch(e => {
                console.log({e});
                if(e && e.response &&  e.response.status && e.response.data && e.response.data.includes('ProgrammingError ')){
                    notification('error',  'Please check search string is correct')
                } else {
                    notification('error',  'Oops!! something went wrong')
                }
                
                dispatch(setLoading(false));

            });
    }
}

export const setLoadingDetail = (payload) => ({
    type: ActionTypes.SET_PART_DETAIL_LOADING,
    payload
});
export const PartImageSuccess = (payload) => ({
    type: ActionTypes.GET_PART_DETAIL_IMAGE_SUCCESS,
    payload
});
export const PartDetailSuccess = (payload) => ({
    type: ActionTypes.GET_PART_DETAIL_SUCCESS,
    payload
});
export const  PartDetailError = (payload) => ({
    type: ActionTypes.GET_PART_DETAIL_ERROR,
    payload
});

export const  flushPartDetail = () => ({
    type: ActionTypes.FLUSH_PART_DETAIL
});
export const  flushCart = () => ({
    type: ActionTypes.FLUSH_CART
});
export const  getPartDetail = (payload) => {
    return dispatch => {
        dispatch(setLoadingDetail(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/api/parts-detail` ,{ids:payload},
        )
            .then(res => {
                dispatch(setLoadingDetail(false));
                if (res.status == 200 && res.data ) {
                    dispatch(PartDetailSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                    dispatch(PartDetailError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setLoadingDetail(false));

            });
    }
}
export const  getPartImage = (payload) => {
    return dispatch => {
        dispatch(setLoadingDetail(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/api/parts-detail` ,{ids:payload},
        )
            .then(res => {
                dispatch(setLoadingDetail(false));
                if (res.status == 200 && res.data && res.data[0] ) {
                    dispatch(PartImageSuccess(res.data[0]));
                } else {
                    notification('error',  pt("oops"))
                    // dispatch(PartImageError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setLoadingDetail(false));

            });
    }
}

export const addToCartSuccess = (payload) => ({
    type: ActionTypes.ADD_TO_CART_SUCCESS,
    payload
});
export const  addToCartError = (payload) => ({
    type: ActionTypes.ADD_TO_CART_ERROR,
    payload
});
export const  addToCart = (payload, type='updated') => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setLoadingDetail(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/api/shops/cart`,payload,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                dispatch(setLoadingDetail(false));
                if (res.status == 200 && res.data ) {
                    
                    notification('success', pt(`cart.${type}`))
                    dispatch(addToCartSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                   // dispatch(addToCartError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setLoadingDetail(false));

            });
    }
}
export const  getCart = (payload) => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setLoadingDetail(true));
        Axios.get(`${process.env.REACT_APP_API_URL}/api/shops/cart`,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                dispatch(setLoadingDetail(false));
                if (res.status == 200 && res.data ) {
                    dispatch(addToCartSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                   // dispatch(addToCartError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setLoadingDetail(false));

            });
    }
}




export const setQuotationLoading = (payload) => ({
    type: ActionTypes.SET_QUOTATION_LOADING,
    payload
})


export const getQuotationsSuccess = (payload) => ({
    type: ActionTypes.GET_QUOTATIONS_SUCCESS,
    payload

});

export const  getQuotationsError = (payload) => ({
    type: ActionTypes.GET_QUOTATIONS_ERROR,
    payload

});
export const  getQuotations = () => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setQuotationLoading(true));
        Axios.get(`${process.env.REACT_APP_API_URL}/api/rental/quotations/`,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                dispatch(setQuotationLoading(false));
                if (res.status == 200 && res.data ) {
                    dispatch(getQuotationsSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                   // dispatch(addToCartError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setQuotationLoading(false));

            });
    }
}
export const flushQuotation = (payload) => ({
    type: ActionTypes.FLUSH_QUOTATION,
    payload

});
export const getQuotationSuccess = (payload) => ({
    type: ActionTypes.GET_QUOTATION_SUCCESS,
    payload

});

export const  getQuotationError = (payload) => ({
    type: ActionTypes.GET_QUOTATION_ERROR,
    payload

});
export const  getQuotation = (id) => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setQuotationLoading(true));
        dispatch(flushQuotation());
        Axios.get(`${process.env.REACT_APP_API_URL}/api/rental/quotations/${id}`,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                dispatch(setQuotationLoading(false));
                if (res.status == 200 && res.data ) {
                    dispatch(getQuotationSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                   // dispatch(addToCartError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setQuotationLoading(false));

            });
    }
}
export const createQuotationSuccess = (payload) => ({
    type: ActionTypes.CREATE_QUOTATION_SUCCESS,
    payload

});

export const  createQuotationError = (payload) => ({
    type: ActionTypes.CREATE_QUOTATION_ERROR,
    payload

});
export const  createQuotation = (payload,history) => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setQuotationLoading(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/api/rental/quotations/`,payload,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(({status,data}) => {
                dispatch(setQuotationLoading(false));
                if (status == 201 && data && !data.error ) {
                    dispatch(createQuotationSuccess(data));
                    dispatch(flushCart());
                    notification('success', 'Quotation generated')
                    history.push(`/quotations/${data.id}`)
                } else {
                    notification('error',  pt("oops"))
                }
            })
            .catch(({response:{data,status}}) => {
                dispatch(setQuotationLoading(false));
                if(status==400 && data.error== 'True'){
                    if(data.emptyCart =='True'){
                        dispatch(flushCart());
                        history.push('/parts-finder')
                    }
                    notification('error', data.msg ? data.msg : pt("oops"))
                } else {
                    notification('error',  pt("oops"))
                }

            });
    }
}
export const updateQuotationSuccess = (payload) => ({
    type: ActionTypes.UPDATE_QUOTATION_SUCCESS,
    payload

});

export const  updateQuotationError = (payload) => ({
    type: ActionTypes.UPDATE_QUOTATION_ERROR,
    payload

});

export const  updateQuotation = (id,payload) => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setQuotationLoading(true));
        Axios.put(`${process.env.REACT_APP_API_URL}/api/shops/cart/${id}`,payload,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                dispatch(setQuotationLoading(false));
                if (res.status == 200 && res.data ) {
                    dispatch(updateQuotationSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                   // dispatch(addToCartError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setQuotationLoading(false));

            });
    }
}

export const deleteQuotationSuccess = (payload) => ({
    type: ActionTypes.DELETE_QUOTATION_SUCCESS,
    payload

});

export const  deleteQuotationError = (payload) => ({
    type: ActionTypes.DELETE_QUOTATION_ERROR,
    payload

});

export const  deleteQuotation = (id) => {
    return dispatch => {
        const token = localStorage.getItem('mAuth');
        dispatch(setQuotationLoading(true));
        Axios.delete(`${process.env.REACT_APP_API_URL}/api/shops/cart/${id}`,{
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }
        )
            .then(res => {
                dispatch(setQuotationLoading(false));
                if (res.status == 200 && res.data ) {
                    dispatch(deleteQuotationSuccess(res.data));
                } else {
                    notification('error',  pt("oops"))
                   // dispatch(addToCartError(res.data));
                }
            })
            .catch(e => {
                
                notification('error',  pt("oops"))
                dispatch(setQuotationLoading(false));

            });
    }
}