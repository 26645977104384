import React, { useState } from 'react';
import { Table, Button, Tooltip } from 'antd';
import Pagination from './Pagination'
import { ShoppingCartOutlined, EyeOutlined } from '@ant-design/icons';
import { pt } from '../utils';
import scrollToEl from '../scrollToEl'
function Plotter(props) {
    const [expandedRowKeys, setexpandedRowKeys] = useState([])
    const [miniPage, setMiniPage] = useState({ page: 1, pageSize: 5 })
    const el = {
        ellipsis: {
            showTitle: false,
        },
        render: val => (
            <Tooltip placement="topLeft" title={val}>
                {val}
            </Tooltip>
        ),
    }
    const onPaginationChangeMini = (page, pageSize) => {
        setMiniPage({ page, pageSize })

    }

    const expandedRowRender = () => {
        const columns = [
            {
                title: pt('parts.SN'), dataIndex: 'SN', key: 'SN', width: '40px',
                render: (value, row, index) => ((miniPage.page - 1) * miniPage.pageSize) + index + 1,
            },
            { title: pt('parts.SupplierName'), dataIndex: 'name', key: 'name', width: '100px', ...el },
            { title: pt('parts.Parts'), dataIndex: 'part_number', width: '70px', key: 'part_number', ...el },
            { title: pt('parts.Brand'), dataIndex: 'brand', width: '70px', key: 'brand', ...el },
            { title: pt('parts.Description'), dataIndex: 'description', key: 'description', width: '150px', ...el },
            { title: pt('parts.MSRP'), dataIndex: 'msrp', width: '50px', key: 'msrp', ...el },
            { title: pt('parts.Availability'), dataIndex: 'availability', key: 'availability', width: '100px', ...el },


            {
                title: '',
                dataIndex: 'operation',
                key: 'operation',
                width: '90px',
                render: (d, row) => (
                    <>
                        <ShoppingCartOutlined
                            onClick={e => props.addToCart(row)}
                            style={{ fontSize: '20px', color: '#08c', marginRight: '5px' }}
                        />
                        <EyeOutlined
                            style={{ fontSize: '20px', color: '#08c', }}
                            onClick={e => props.showDrawer(row)}
                        />
                    </>
                ),
            },
        ];

        const data = props.detail ? props.detail : [];

        return <Table
            // title={title}
            showSorterTooltip={false}
            pagination={{
                simple: true,
                size: 'small',
                pageSize: 5,
                hideOnSinglePage: true,
                onChange: onPaginationChangeMini
            }}
            indentSize={10}
            bordered={true}
            columns={columns}
            dataSource={data}
            // pagination={false}
            loading={props.loadingDetail}
            scroll={{
                scrollToFirstRowOnChange: false,
                x: undefined,
                y: 300
            }}
        />;
    };

    const columns = [
        {
            title: pt('parts.SN'), dataIndex: 'sn', key: 'sn',
            width: '20px',
            render:(k,r, index)=>((current-1)*20)+index+1
        }, 
               {
            title: pt('parts.Description'), dataIndex: 'description', key: 'description',
            ...el,
            width: '200px',
            sorter: {
                compare: (a, b) => a.description.length-(b.description).length,
            },
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: pt('parts.ManufacturerParts'), dataIndex: 'manufacturer_part_number', key: 'manufacturer_part_number',
            width: '100px',
            ...el,
            sorter: {
                compare: (a, b) => a.manufacturer_part_number.length-(b.manufacturer_part_number).length,
            },
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: pt('parts.Parts'), dataIndex: 'part_number', key: 'parts',
            width: '100px',
            ...el,
            sorter: {
                compare: (a, b) => a.part_number.length -(b.part_number).length,
            },
            sortDirections: ['ascend', 'descend', 'ascend']
        },

        {
            title: pt('parts.SupplierName'), dataIndex: 'name', key: 'name',
            width: '100px',
            ...el,
            sorter: {
                compare: (a, b) => a.name.length - (b.name).length,
            },
            sortDirections: ['ascend', 'descend', 'ascend']
        },

        {
            title: pt('parts.Brand'), dataIndex: 'brand', width: '70px', key: 'brand', ...el,
            sorter: {
                compare: (a, b) => a.brand.length - (b.brand).length,
            },
            sortDirections: ['ascend', 'descend', 'ascend']
        },
        {
            title: pt('parts.MSRP'), dataIndex: 'msrp', width: '50px', key: 'msrp', ...el,
            sorter: {
                compare: (a, b) => a.msrp - (b.msrp),
            },
            sortDirections: ['ascend', 'descend', 'ascend']

        },
        {
            title: pt('parts.Availability'), dataIndex: 'availability', key: 'availability', width: '100px', ...el,
            render:(st)=>st==-1 ? 'Contact supplier':st,
            sorter: {
                compare: (a, b) => a.availability - (b.availability),
            },
            sortDirections: ['ascend', 'descend', 'ascend']
           
        },


        {
            title: '',
            dataIndex: 'operation',
            key: 'operation',
            width: '90px',
            render: (d, row) => (
                <>
                    <ShoppingCartOutlined
                        onClick={e => props.addToCart(row)}
                        style={{ fontSize: '20px', color: '#08c', marginRight: '5px' }}
                    />
                    <EyeOutlined
                        style={{ fontSize: '20px', color: '#08c', }}
                        onClick={e => props.showDrawer(row)}
                    />
                </>
            ),
        },




    ];

    let { data, current, loading } = props;
    let total = data && data.total ? data.total : 0;
    let results = data && data.data && data.data.length > 0 ? data.data : [];


    const onExpand = (expanded, row) => {
        if (expanded) {
            setexpandedRowKeys([row.manufacturer_part_number]);

            // scrollToEl('.expand-parent', +200, 1)
            // setTimeout(()=>{
            //     scrollToEl('.active-expanded-custom', +20, 1)
            // },500)
            let ids = row && row.ids ? row.ids.split(',') : []
            props.getPartDetail(ids)
        } else {
            setexpandedRowKeys([])
            props.flushPartDetail()
        }

    }



    const onRow = ({ manufacturer_part_number }) => expandedRowKeys.includes(manufacturer_part_number) && { className: "expand-parent" }
    const onChange =(e,v,b,n,m)=>{
        console.log(b);
        props.apiSort({
            key:b.field=='name' ? 's.name' :`p.${b.field}`,
            order:b.order=='ascend' ? 'ASC':'DESC'})
    }

    return (
        <>

            <Pagination
                current={current}
                loading={loading}
                data={data}
                onPaginationChange={props.onPaginationChange}
            />

            <Table
                showSorterTooltip={false}
                indentSize={10}
                loading={props.loading}
                onChange={onChange}
                //style={{height:'200px'}}
                scroll={{
                    scrollToFirstRowOnChange: false,
                    y: data && data.length > 3 ? 700 : undefined,
                }}
                className="components-table-demo-nested"
                columns={columns}
                // expandable={{
                //     expandRowByClick: true,
                //     expandedRowRender, onExpand: onExpand, expandedRowKeys: expandedRowKeys,
                //     expandedRowClassName: () => "active-expanded-custom",
                //     indentSize: 2
                // }}

                onRow={onRow}
                dataSource={results}
                rowKey="manufacturer_part_number"
                sticky={true}
                pagination={false}
                rowKey={record=>`${record.id}${record.brand}${record.manufacturer_part_number}`}


            />

            <Pagination
                current={current}
                loading={loading}
                data={data}
                onPaginationChange={props.onPaginationChange}
            />


        </>
    );
}

export default Plotter;