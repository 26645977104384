import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout, Drawer } from 'antd';
import Dashboard from '../Dashboard/Dashboard';
import PartsFinder from '../Parts/PartsFinder'
import Cart from '../Parts/Cart'
import Quotations from '../Parts/Quotations'
import Quotation from '../Parts/Quotation'
import ImageLarge from '../Parts/ImageLarge'
import GenerateQuotation from '../Parts/GenerateQuotation'
import HeaderBar from './HeaderBar'
import SideBar from './SideBar';
import './antdIntegration.css'

const { Header, Content, Footer, Sider } = Layout;

//import Home from './Home/Home'
class AuthenticateApp extends React.Component {
  state = {
    collapsed: window.innerWidth < 576,
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  CollapseSidebar = (e) => {
    e.preventDefault();
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  }

  outSideClickforMobile = () => {
    if (window.innerWidth < 576 && !this.state.collapsed) {
      this.setState({ collapsed: true })
    }
  }

  componentDidUpdate(preveProps){
    if(window.innerWidth < 576 && this.props.location.pathname !== preveProps.location.pathname && !this.state.collapsed ){
      this.setState({ collapsed: true })
    }
  }



  render() {
    const { authenticated, location, logout } = this.props;
    const publicPath = ['/login', '/logout', '/forgot-password', '/reset-password']
    const { collapsed } = this.state;

    if (window.innerWidth > 576) {
      document.body.className = collapsed ? 'fixed-navbar has-animation sidebar-mini' : 'fixed-navbar has-animation ';
    } else {
      document.body.className = collapsed ? 'fixed-navbar has-animation drawer-sidebar ' : 'fixed-navbar has-animation drawer-sidebar has-backdrop';
    }

    if (!authenticated) {
      return <Redirect to="/login" />
    }
    if (location && location.pathname && publicPath.includes(location.pathname)) {
      return <Redirect to="/" />
    }
    if (authenticated) {
      return (
        <div className="page-wrapper">
          <Layout>
            <Header style={{ position: 'fixed', zIndex: 1002, width: '100%' }} className="header">

              <HeaderBar CollapseSidebar={this.CollapseSidebar} logout={logout} lng={this.props.lng} lngChange={this.props.lngChange} />

            </Header>
            <Layout>
              {window.innerWidth < 576 ? <Drawer
                title={false}
                placement="left"
                closable={false}
                zIndex={10000}
                width={230}
                className="mobile-left-drawer"
                onClose={this.outSideClickforMobile}
                visible={!collapsed}
                drawerStyle={{
                  backgroundColor:'#304357',
                  height:'100vh'
                }}
                destroyOnClose={true}
              >
                <SideBar
                    logout={logout}
                    outSideClickforMobile={this.outSideClickforMobile}
                    visible={true}
                    location={this.props.pathname}
                  />
              </Drawer>
                :
                <Sider collapsed={collapsed} onCollapse={this.onCollapse} collapsedWidth={window.innerWidth < 576 ? 0 : 70} width={230} style={{ minHeight: '100vh' }}>
                  <SideBar
                    logout={logout}
                    outSideClickforMobile={this.outSideClickforMobile}
                    visible={!collapsed}
                    location={this.props.pathname}
                  />
                </Sider>
              }


              <Content >
                <div className="site-layout-background" >
                  <div className="content-wrapper" >
                    <Switch>
                      <Route path="/parts-finder" component={PartsFinder} />
                      <Route path="/cart" component={Cart} />

                      <Route path="/part-image/:id" component={ImageLarge} />
                      <Route path="/quotations/:id" component={Quotation} />
                      <Route path="/quotations" component={Quotations} />
                      <Route path="/quotation/generate" component={GenerateQuotation} />
                      <Route path="/" component={PartsFinder} />
                    </Switch>
                  </div>
                </div>
              </Content>
            </Layout>
          </Layout>
        </div>
      )
    }
  }
}

export default AuthenticateApp;
