import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Login from './Login/Login'
import ForgetPassword from './Login/ForgetPassword'
import ResetPassword from './Login/ResetPassword'
class UnAuthenticateApp extends React.Component {

  render() {
    const { authenticated,location } = this.props;
    const publicPath =['/login','/forgot-password','/reset-password']
    
    if (authenticated) {
      return <Redirect to="/" />
    }
    const splitted =location.pathname.split('/');
    console.log({splitted});
    if (location.pathname.split('/').includes('reset-password')){

    }
    else if(location && location.pathname && !publicPath.includes(location.pathname) ){
      return <Redirect to="/login" />
    }
    if (!authenticated ) {
      return (
        <Switch>
          <Route path="/forgot-password" component={ForgetPassword} />
          <Route path="/reset-password/:uid/:token" component={ResetPassword} />
          <Route path="/login" component={Login} />
          <Route path="/" component={Login} />

        </Switch>
      )
    }
  }
}

export default UnAuthenticateApp;
