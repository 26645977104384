import React from 'react';
import { Drawer, Button, Card, Row, Col } from 'antd';
import { Typography, Space, Divider } from 'antd';
import ImageThumb from './ImageThumb'
import { pt } from '../utils';
import { ShoppingCartOutlined, CloseCircleOutlined } from '@ant-design/icons';
const { Text, Link } = Typography;
class SiderInfo extends React.Component {
  constructor(props) {
    super(props);
  }



  formatLabel = (label) => {
    label = label.split('_');
    label = label.join(' ');
    label = label.replace(/\b\w/g, l => l.toUpperCase())
    return label;
  }

  render() {
    const { data } = this.props;
    const exclude = ['large_image', 'thumb_image']
    const arr = [
      { key: 'manufacturer_part_number', label: 'Manufacturer Part Number' },
      { key: 'name', label: 'Supplier' },
      { key: 'part_number', label: 'Part Number' },
      { key: 'msrp', label: 'Amount' },
      { key: 'brand', label: 'Brand' },
      { key: 'description', label: 'Description' },

    ];

    const Header = () => <Row className="header-part" >
      <Col span={12} xs={{ span: 12 }} >
        <ImageThumb data={data} />
        <Text>{pt('parts.Detail')}</Text>
      </Col>
      <Col span={12} xs={{ span: 12 }}>
        <div className="float-right" >
          <ShoppingCartOutlined
            onClick={e => this.props.addToCart(data)}
            title="Add to Cart"
            style={{ fontSize: '20px', color: '#08c', marginRight: '5px' }}
          />
          <CloseCircleOutlined
            title="Close"
            onClick={e => this.props.onClose(data)}
            style={{ color: ' #ffccc7', marginLeft: '15px', fontSize: '20px', }} />
        </div>

      </Col>

    </Row>
    //console.log(window.innerHeight);
    return (
      <div className="site-drawer-render-in-current-wrapper">

        <Drawer
          placement="right"
          className="part-drawer"
          width={450}
          closable={false}
          onClose={this.props.onClose}
          visible={this.props.visible}
          getContainer={false}
          style={{
            //position: 'static',
            zIndex: 999999,
           //height:window.innerHeight,
            scrollbarWidth: 'thin' 
            // maxHeight:'100vh',
            // overflow:'hidden'
          }}
        >

          <Card ref={this.ref} className="part-siderinfo-header" title=''>
            <Header />
          </Card>
          <div className="sideer-wrapper" 
          //style={{  height:(window.innerHeight-90),scrollbarWidth: 'thin'  }}
          >
            <Card className="part-card ant-alert-info" style={{ marginBottom: '10px' }} title="">
              {
                arr.map(obj => <Row key={obj.key} style={{ padding: '7px 0' }}>
                  <Col span={12} xs={{ span: 12 }} >
                    <Text style={{ fontWeight: 'bold' }}>{pt(`parts.${obj.key}`)}:</Text>
                  </Col>
                  <Col span={12} xs={{ span: 12 }}>
                    <Text type="secondary">
                      {data && data[obj.key] ? data[obj.key] : ''}
                    </Text>
                  </Col>
                  {/* <Divider style={{ margin: '7px 0' }} /> */}
                </Row>)
              }
            </Card>

            <Card
              className="part-card ant-alert-warning"

              title={
                <Text style={{ fontWeight: 'bold', color: '#fff' }}>{pt(`parts.AdditionalInformation`)}</Text>
              }>
              <div className="meta_data_part">
                {
                  data && data.extra_data ? Object.keys(data.extra_data).map(datum => exclude.includes(datum) ? null : <Row key={datum} style={{ padding: '7px 0' }}>
                    <Col span={12} xs={{ span: 12 }} >
                      <Text style={{ fontWeight: 'bold' }}>{this.formatLabel(datum)}: </Text>
                    </Col>
                    <Col span={12} xs={{ span: 12 }}>
                      <Text type="secondary">
                        {data.extra_data && data.extra_data[datum] ? data.extra_data[datum].toString() : ''}
                      </Text>
                    </Col>
                    {/* <Divider style={{ margin: '2px 0' }} /> */}
                  </Row>) : null
                }
              </div>
            </Card>
          </div>

        </Drawer>
      </div>
    );
  }
}

export default SiderInfo;