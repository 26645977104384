import { createStore, applyMiddleware, combineReducers } from 'redux';
import accounts from './Containers/Accounts/store/Reducer'
import parts from './Containers/Parts/store/Reducer'
//import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
//const logger = createLogger({ collapsed: true });
const rootReducer = combineReducers({
  accounts:accounts,
  parts:parts
});
const store = createStore(
  rootReducer,
  //composeWithDevTools(applyMiddleware(thunk, logger))
  composeWithDevTools(applyMiddleware(thunk))
)

export default store;
