import {notification  as antdNotification} from 'antd';
import { useTranslation } from 'react-i18next'
const notification = (type, message) => {
  antdNotification[type]({
    message, top:10,duration:2
    
  });
};

const pt = (str) => {
  const lng = localStorage.getItem('lng') ? localStorage.getItem('lng') :'en';
   const en = require('../locales/en/translation.json');
   const fr = require('../locales/fr/translation.json');
   let translated='';
   str = str.split('.');
   if(str.length ==3){
       if(lng =='fr' && fr[str[0]][str[1]][str[2]]){
           translated = fr[str[0]][str[1]][str[2]];
       }
       else if (lng =='en' && en[str[0]][str[1]][str[2]]){
           translated =  en[str[0]][str[1]][str[2]];
       }
   }
   else if  (str.length ==2){
       if(lng =='fr' && fr[str[0]][str[1]]){
           translated = fr[str[0]][str[1]];
       }
       else if (lng =='en' && en[str[0]][str[1]]){
           translated =  en[str[0]][str[1]];
       }
   }
   
  
   
   return translated ? translated :''
}

export { pt, notification}; 