import i18n from "i18next";
import { initReactI18next  } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};

i18n
    .use(initReactI18next ) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lng') ? localStorage.getItem('lng'):'en',
        fallbackLng: "en", // use en if detected lng is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;