import * as ActionTypes from './ActionTypes';
import {pt}  from '../../utils';
import Axios from 'axios';
import { notification } from '../../utils';
export const setLoading = (payload) => ({
    type: ActionTypes.ACCOUNTS_LOADING,
    payload
})


// export const changePassword = (payload) => {

//     return dispatch => {

//         dispatch(setLoading(true));

//         let url = `orders/${payload.id}`;

//         Axios.put(`${process.env.REACT_APP_API_URL}/${url}`,payload
//         )
//             .then(res => {
//                 dispatch(setLoading(false));
//                 if (res.data) {
//                     notification('success', 'Order canceled')
//                     dispatch(cancelOrdersSuccess(res.data));
//                 } else {
//                     notification('error',  pt("oops"))
//                 }
//             })
//             .catch(e => {
//                 dispatch(setLoading(false));
//                 notification('error',  pt("oops"))
//             });
//     }
// }

//login, 
export const setLoadingAuth = (payload) => ({
    type: ActionTypes.SET_AUTH_LOADING,
    payload

})
export const loginSuccess = (payload) => ({
    type: ActionTypes.LOGIN_SUCCESS,
    payload

})
export const forgotPassword = (payload) => {
    return dispatch => {
        dispatch(setLoading(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/password/reset/`, payload
        )
            .then(res => {
                dispatch(setLoading(false));
                if (res.status == 200 && res.data && res.data.detail) {
                    notification('success', pt("login.mailSent"))
                } else {
                    notification('error',  pt("oops"))
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status == 400) {
                    notification('error', pt("login.e.InvalidEmail"));
                } else {
                    notification('error', pt("oops"))
                }
                dispatch(setLoading(false));

            });
    }
}
export const resetpassword = (payload,history) => {
    return dispatch => {
        dispatch(setLoading(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/password/reset/confirm/`, payload
        )
            .then(res => {
                dispatch(setLoading(false));
                if (res.status == 200 && res.data && res.data.detail) {
                    notification('success', pt("login.resetDone"))
                    history.push('/login')
                } else {
                    notification('error',  pt("oops"))
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status == 400 &&  e.response.data && e.response.data.new_password2) {
                    notification('error', pt("login.e.passwordtoocomon"));
                }
                else if (e && e.response && e.response.status == 400 ) {
                    notification('error', pt("login.e.passwordInvalid"));
                }
                 else {
                    notification('error', pt("oops"))
                }
                dispatch(setLoading(false));

            });
    }
}
export const doLogin = (payload) => {
    return dispatch => {
        dispatch(setLoading(true));
        Axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/login/`, payload
        )
            .then(res => {
                dispatch(setLoading(false));
                if (res.status == 200 && res.data && res.data.access_token) {
                    notification('success', pt("login.success"))
                    localStorage.setItem('mAuth', res.data.access_token);
                    dispatch(loginSuccess(res.data.access_token));
                    dispatch(getUser());
                } else {
                    notification('error',  pt("oops"))
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status == 400) {
                    notification('error', pt("login.e.Invalid"));
                } else {
                    notification('error', pt("oops"))
                }
                dispatch(setLoading(false));

            });
    }
}
export const getUserDetailSuccess = (payload) => ({
    type: ActionTypes.GET_USER_SUCCESS, payload
})
export const getUser = (history) => {
    return dispatch => {

        const token = localStorage.getItem('mAuth');

        if (token) {
            dispatch(setLoadingAuth(true));
            Axios.get(`${process.env.REACT_APP_API_URL}/dj-rest-auth/user/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            }
            )
                .then(res => {
                   
                    if (res.status == 200 && res.data && res.data) {
                        dispatch(getUserDetailSuccess(res.data));
                        dispatch(setLoadingAuth(false));

                    } else {
                        notification('error', 'Oops!! something went wrong2S')
                    }
                })
                .catch(e => {
                    dispatch(setLoadingAuth(false));
                    if (e && e.response && e.response.status == 400) {
                        notification('error', 'Credentials Invalid');
                    } else {
                        localStorage.clear();
                        history.push('/login');
                    }


                });
        } else {
            dispatch(setLoadingAuth(false));
        }

    }
}
export const logoutSuccess = () => ({
    type: ActionTypes.LOGOUT_SUCCESS,
})


export const logout = (history) => {
    return dispatch => {
        
        dispatch(logoutSuccess());
        history.push('/login')
        return;
        Axios.post(`${process.env.REACT_APP_API_URL}/dj-rest-auth/logout`,
        )
            .then(res => {
                dispatch(setLoading(false));
                if (res.status == 200 ) {
                    notification('success', 'login success')
                    localStorage.removeItem('mAuth');
                    dispatch(logoutSuccess());
                } else {
                    notification('error',  pt("oops"))
                }
            })
            .catch(e => {
                if (e && e.response && e.response.status == 400) {
                    notification('error', 'Credentials Invalid');
                } else {
                    notification('error',  pt("oops"))
                }
                dispatch(setLoading(false));

            });
    }
}