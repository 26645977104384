import React from 'react';
import { Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AuthenticateApp from './Containers/AuthenticateApp/AuthenticateApp';
import UnAuthenticateApp from './Containers/UnAuthenticateApp';
import PreLoader from './Containers/Loader/Loader';
import { getUser,logout } from './Containers/Accounts/store/Actions';
import './assets/css/antd.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      lng: localStorage.getItem('lng') ? localStorage.getItem('lng') :'en'
    }
    
  }

  componentDidMount() {
      this.props.getUser(this.props.history);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.loading && prevProps.authenticated != this.props.authenticated && this.props.authenticated) {
  //     this.setState({ loading: false });
  //   }
  //   else if (prevState.loading && this.props.authenticated ===undefined && this.props.authLoading === false) {
  //     this.setState({ loading: false });
  //   }
  //   if(this.props.authenticated && !this.props.user && prevProps.authenticated != this.props.authenticated && this.props.authenticated){
  //     this.props.getUser(this.props.history);
  //   }
  // }


  logout =()=>{
    this.props.logout(this.props.history)
  }
  lngChange =(lng)=>{
    localStorage.setItem('lng',lng)
    this.setState({lng})
  }

  render() {
    const { authenticated, authLoading,location } = this.props;
    const {lng}=this.state;
   // console.log(authenticated, authLoading,location);
    return (
      <>
        {   (authLoading===true || authLoading === undefined) ? <PreLoader /> : <>
          {
            authenticated  ?  
            <AuthenticateApp 
            logout ={this.logout} 
            authenticated={authenticated} 
            location={location}  
            getUser={this.props.getUser}
            lng={lng} 
            lngChange={this.lngChange}
            />
            :<UnAuthenticateApp 
            authenticated={authenticated}  
            location={location}
            lng={lng} 
            lngChange={this.lngChange}
            />
          }
         

        </>
        }
      </>
    )
  }
}
const mapStateToProps = ({ accounts }) => ({
  loading: accounts.loading,
  user: accounts.user,
  authLoading: accounts.authloading,
  authenticated: accounts.authenticated,
});

const mapDispatchToProps = dispatch => ({
  getUser: (history) => dispatch(getUser(history)),
  logout: (history) => dispatch(logout(history))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));
