import React from 'react';
import { pt } from '../utils';
import { Table,Input} from 'antd';
const  CartItems =({data})=>{
        const columns = [

            {
                title: pt('cart.SN'), dataIndex: 'SN.', key: 'SN.', width: '20px',
                render: (value, row, index) => !row.dummy ? index + 1 : '',
            },
            { title: pt('cart.Parts'), dataIndex: 'part_number', key: 'part_number' },
            { title: pt('cart.Manufacturer'), dataIndex: 'manufacturer_part_number', key: 'manufacturer_part_number' },
            { title: pt('cart.Description'), dataIndex: 'description', key: 'description', width: '200px' },
            { title:  pt('cart.Supplier'), dataIndex: 'supplier', key: 'supplier', width: '150px' },
            {
                title: pt('cart.Quantity'), dataIndex: 'quantity', key: 'quantity', width: '170px',
            },
            { title: pt('cart.Price'), dataIndex: 'msrp', key: 'msrp', width: '70px' },
            {
                title: pt('cart.Total') , dataIndex: 'total', key: 'total',
                render: (d, row) => row.dummy && row.bold ? <span style={{ fontWeight: 'bold' }}>{d}</span> : row.dummy && !row.bold ? d : (row.msrp * row.quantity).toFixed(2)
            },

        ]
        let dataSource = data;
        let totalItems = 0;
        let subTotal = 0;
        let shipping = 0;
        let grandTotal = 0;
        let addtionalRows = [];
        if (data && data.length > 0) {
            data.map(item => {
                totalItems = totalItems + item.quantity;
                const { msrp, quantity } = item;
                //console.log(item);
                subTotal = subTotal + (parseFloat(msrp) * parseFloat(quantity));

            })
            grandTotal = subTotal - shipping;
            addtionalRows.push({ dummy: true, quantity: `${pt('cart.Subtotal')} (${totalItems} ${pt('cart.items')}) `, total: `$${subTotal}` })
            // addtionalRows.push({ dummy: true, quantity: `Shipping (Flat Rate) `, total: `$${shipping}` })
            addtionalRows.push({ dummy: true, bold: true, quantity: `${pt('cart.Total')}`, total: `$${grandTotal}` })
            dataSource = dataSource.concat(addtionalRows)
        }

        return (            
                <div className="table-responsive">
                    <Table columns={columns} dataSource={dataSource} pagination={false} />
                </div>
        )
}

export default CartItems; 