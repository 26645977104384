import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';
 const Translate =({k})=> {
   
  const { t, i18n } = useTranslation()
    return (<>{t(k)}</>)
 }
const mapStateToProps = state => ({
    lng: state
  });
  
  const mapDispatchToProps = dispatch => ({});
   export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Translate);