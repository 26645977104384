import React from 'react';
import {  Link } from 'react-router-dom';
import { connect } from 'react-redux';
const Dashboard = (props) => {
    const {location:{pathname}}=props;
    const isActive =(url)=>{
        return url == pathname ? 'active':'';
    }
    return (
       <h1>dashboard</h1>
    )
}

const mapStateToProps = state => ({
    
});

const mapDispatchToProps = dispatch => ({
    //logout: () => dispatch(logout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);