import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout, Button, Row, Col, Tag } from 'antd';
import { connect } from 'react-redux';
import Plotter from './Plotter';
import {
  searchParts, getPartDetail, flushPartDetail, addToCart,
  searchPartSTR, flushSearch, flushAdvanceSearch
} from './store/Actions';
import SiderInfo from './SiderInfo'
import Filters from './Filters'
import { pt } from '../utils';
import scrollToEl from '../scrollToEl'
import { Typography, Space } from 'antd';
import { Checkbox } from 'antd';
const { Text } = Typography;
class PartsFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      visible: false,
      current: 1,
      advanceSearchShow: false,
      form: undefined,
      advanceFilterdata: undefined,
      advance: false,
      plotterKey: (new Date()).getTime(),
      error: undefined,
      availabilityLimit:1,
      isAvailabilityLimit:false,
      order:{key:'p.description',order:'ASC'},
      operator: ['AND', 'OR',  '=', '!=', '>', '<', '>=', '<=', '%', '(', ')'],
      columns: { 'desc': 'description', 'mpn': 'manufacturer_part_number', 'pn': 'part_number', 'msrp': 'msrp', 'br': 'brand' }
    }
  }

  componentDidMount() {
    this.props.flushSearch()
  }

  showDrawer = (data) => {
    this.setState({ visible: true, info: data, advanceSearchShow: false })
  };

  onClose = () => {
    this.setState({ visible: false, info: undefined })
  };

  showAdvanceDrawer = (data) => {
    this.setState({ advanceSearchShow: true, visible: false })
  };

  onAdvanceClose = () => {
    this.setState({ advanceSearchShow: false })
  };

  addToCartSider = (item) => {
    this.setState({ visible: false, info: undefined }, () => this.addToCart(item))
  }
  addToCart = (item) => {

    let { cart } = this.props;
    let items = [];
    let found = false;
    if (cart && cart.length > 0) {
      for (const c of cart) {
        if (c.id == item.id) {
          found = true;
          items.push({ id: item.id, quantity: (c.quantity + 1) })
        } else {
          items.push({ id: c.id, quantity: c.quantity })
        }
      }
    }
    if (!found) {
      items.push({ id: item.id, quantity: 1 })
    }

    this.props.addToCart(items, 'added')
  }

  onKeyDown = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      this.setState({ advance: false }, () => this.searchParts())

    }
  }

  onChnageSearch = (e) => {
    const { value } = e.target;
    this.props.searchPartSTR(value);
  }

  getFirstOperator = (str, operator) => {
    let position;
    let found;
    for (const op of operator) {
      if (str.includes(op)) {
        if (position !== undefined) {
          if (position > str.indexOf(op)) {
            position = str.indexOf(op);
            found = op
          }
        } else {
          position = str.indexOf(op);
          found = op
        }
      }
    }
    return found
  }

  getValueAndFormat =(str,val,oprtr, opp)=>{
    let mathOperator =[ '>=', '<=', '!=', '=','>', '<','%',]
    let extra =['(',')','[',']','%']
    let operand =this.getFirstOperator(str, mathOperator)
    if(operand){
      let splittedstr= str.split(operand);
      let valuePart =splittedstr[1]
      if(valuePart){
        if(str.includes('%')){
          const position = str.indexOf(operand);
          str =[str.slice(0, position), ' LIKE ', str.slice(position)].join('');
          str =str.replaceAll('=','')
          mathOperator.map(op=>{
            if(op !='%'){
              valuePart =valuePart.replaceAll(op,'')
            }
           
          })
          extra.map(op=>{
            if(op !='%'){
              valuePart =valuePart.replaceAll(op,'')
            }
            
          })
          // valuePart =valuePart.replaceAll(/\s/g,'')
          valuePart = valuePart.trim()
          val.push(valuePart)
          oprtr.push(opp)
          // str =str.replaceAll('%','')
          str =str.replaceAll(valuePart,' %s')
        }else {
          mathOperator.map(op=>{
            valuePart =valuePart.replaceAll(op,'')
          })
          extra.map(op=>{
            valuePart =valuePart.replaceAll(op,'')
          })
          valuePart =valuePart.replaceAll(/\s/g,'')
          val.push(valuePart)
          oprtr.push(opp)
          str =str.replaceAll('%','')
          str =str.replaceAll(valuePart,' %s')

        }
      }
    }else {
      val.push('')
      oprtr.push(opp)
    }

    return {str,val,oprtr}
  }
  splitIt = (str, mergeIn, value, oprtr) => {
    let logicalOperator = ['AND', 'OR', 'IN', 'NOT IN']
    
    const op =this.getFirstOperator(str, logicalOperator)
    if (op) {
      if (str.includes(op)) {
        const splittedArr = str.split(op)
        let partFirst = splittedArr[0]
        partFirst = this.getValueAndFormat(partFirst,value,oprtr,op)
        mergeIn.push(partFirst)
        splittedArr.shift()
        str = splittedArr.join(op)
        this.splitIt(str, mergeIn,value,oprtr)
      }else {
        //console.log(str);
      }
    }
    return mergeIn
  }

  getQuery = (str) => {
    let isQry = false;
    let anyColumn = false;
    let error;
    str = str.toLowerCase()
    const { operator, columns } = this.state;
    const count = (string, find) => {
      return string.split(find).length - 1;
    }
    for (const op of operator) {
      isQry = str.includes(op.toLocaleLowerCase())
      if (isQry) {
        break;
      }
    }
    if (!isQry) {
      return undefined
    }
    for (const op of Object.keys(columns)) {
      anyColumn = str.includes(op.toLocaleLowerCase() + ' ')
        || str.includes(op.toLocaleLowerCase() + '=')
        || str.includes(op.toLocaleLowerCase() + '!')
        || str.includes(op.toLocaleLowerCase() + '<')
        || str.includes(op.toLocaleLowerCase() + '>')
      if (anyColumn) {
        break;
      }
    }
    if (!anyColumn) {
      return undefined;
    }

    let splittedStr = []

    splittedStr = this.splitIt(str, [],[],[]);
    let formattedStr = ''
    let lastOp = '';
    let lastv; 
    if(splittedStr && splittedStr.length ==0 ){}
    if(splittedStr && splittedStr.length > 0 ){
      splittedStr.map((item,index)=>{
        formattedStr =`${formattedStr} ${item.str} ${item.oprtr[index]}`
        lastOp =item.oprtr[index]
      })
    } else {
      let mathOperator =[ '>=', '<=', '!=', '=','>', '<','%',]
      for (const op of mathOperator){
        if(str.includes(op)){
          splittedStr =this.getValueAndFormat(str,[],[],op)
        }
      }
    }

    if(lastOp){
      let getLastPart =str.split(lastOp)
       getLastPart = getLastPart.pop();
      const v = this.getValueAndFormat(getLastPart,[],[],lastOp)
      if(v && v.val && v.val[0]){
        formattedStr =`${formattedStr} ${v.str}`
        lastv =v.val[0];
      }
    }

    let  arg =[]
    if(anyColumn && !formattedStr && splittedStr){
      formattedStr = splittedStr.str || '';
      arg = splittedStr.val
    }else {
      arg = splittedStr && splittedStr[0]  && splittedStr[0].val ? splittedStr[0].val :[];
    }
    this.setState({ error })
    for (const op of Object.keys(columns)) {
      formattedStr = formattedStr.replaceAll(op, columns[op])
    }
    if(lastv){
      arg.push(lastv)
    }

    // return {qrystr:formattedStr, value : arg}
    return {qrystr:str, value: arg}
  }

  searchParts = (page=1) => {
    let { str } = this.props;
    this.setState({current:page})
    let qrystr = this.getQuery(str)
    // if(qrystr && qrystr.value && qrystr.value.length > 0){
    //   for(const v of qrystr.value){
    //     if(qrystr.qrystr.includes(v)){
    //       qrystr.qrystr =qrystr.qrystr.replaceAll(v,'%s')
    //     }
    //   }
    // }
    const data = qrystr ? { ...qrystr } : undefined
    const {isAvailabilityLimit,availabilityLimit}=this.state
    if (str || data) {
      this.setState({ current: page, advance: false, plotterKey: (new Date()).getTime() })
      this.props.searchParts(data, encodeURI(str), page, 100, this.state.order, isAvailabilityLimit, availabilityLimit)
    }

  }
  onPaginationChange = (page, pageSize) => {
    scrollToEl('#plotter', -10, 1)
    const { str } = this.props;
    const { advanceFilterdata, advance } = this.state;
    this.setState({ current: page, plotterKey: (new Date()).getTime() });

    // this.props.searchParts(advance ? advanceFilterdata : undefined, str, page, pageSize)
    this.searchParts(page)
  }

  advanceSearch = (data, ref) => {
    this.setState({
      advanceFilterdata: data, form: ref, advanceSearchShow: false, advance: true, plotterKey: (new Date()).getTime()
    })
    const { str } = this.props;
    this.props.searchParts(data, str, 1, 20)
  }
  doAdvanceSearch = (data) => {
    this.props.searchParts(data, undefined, 1, 20)
  }
  apiSort =(order) =>{
    this.setState({order},()=>this.searchParts())
    
  }
  removeTag = (tag) => {
    const { advanceFilterdata, form } = this.state;
    if (advanceFilterdata) {
      delete advanceFilterdata[tag];

      const arr = ['part_number', 'manufacturer_part_number', 'brand', 'description', 'msrp', 'availability', 'name']
      let hasValue = false;
      arr.map(a => {
        if (!hasValue && advanceFilterdata && advanceFilterdata[a]) {
          hasValue = true;
        }
      })

      if (hasValue) {
        this.setState({ advanceFilterdata, advance: true, plotterKey: (new Date()).getTime() }, () => this.doAdvanceSearch(advanceFilterdata))

      }
      else {
        this.setState({
          advanceFilterdata: undefined, advance: false, plotterKey: (new Date()).getTime()
        }, () => this.props.flushAdvanceSearch())

      }

    }
  }


  render() {
    const { search, visible, info, current, advanceFilterdata, advance, plotterKey, error, operator, columns } = this.state;
    let { loading, parts, str } = this.props;
    const arr = ['part_number', 'manufacturer_part_number', 'brand', 'description', 'msrp', 'availability', 'name']
    const tagArr = [];
    for (const datum of arr) {
      if (advanceFilterdata && advanceFilterdata[datum]) {
        tagArr.push(datum)
      }
    }
    // str = '((desc=%LED AND mpn=%B2h% OR (desc=rl OR (msrp=>3  or (msrp<5 AND brand=%ab OR (brand =%avc%))))) OR (desc=abc AND mpn=B2h)) AND (msrp>=10 AND pn=1) '
    return (
      <>

        <div className="tc"
        //style={ visible ? {maxHeight:(0), overflow:'hidden'}:{}}
        >
          <div className="page-content fade-in-up pt-1">
            <div className="s003 ">
              <form>
                <div className="input-group">
                  {/* <div className="input-field second-wrap"> */}
                  <input className="search-control border-right-0" value={str} id="search" type="text" placeholder={pt('parts.search')} onChange={this.onChnageSearch} onKeyDown={this.onKeyDown} />
                  {/* </div> */}
                  <span className="input-group-append">
                    <button onClick={e=>this.searchParts(1)} className="btn-main rounded-right" type="button" style={{ padding: '1.1rem 1.25rem' }}><i className="ti-search"></i></button>
                  </span>
                </div>
                <div className="input-group" style={{ marginTop: 5 }}>
                  <Text code type="secondary" style={{ color: 'green' }}> Available operators: {operator.join(', ')}</Text>
                </div>
                <div className="input-group" style={{ marginTop: 5 }}>
                  <Text code type="secondary" > Available Columns: {Object.keys(columns).map(el => ` ${el} => ${columns[el]},`)}</Text>
                </div>
                <div className="input-group" style={{ marginTop: 5 }}>
                  <Text code type="secondary" style={{ color: 'green' }}>When specifying a column, terms must be in between single quotes (')</Text>
                </div>
                <div className="input-group" style={{ marginTop: 5 }}>
                  <Text code type="secondary" >Example: ((desc=%battery% OR desc='%oil filter%' OR msrp>='3' OR br='odx' ) AND pn='84718')</Text>
                </div>
                <div className="input-group" style={{ marginTop: 5 }}>
                  {error && <Text code type="warning">{error}</Text>}
                </div>
                 <div className="input-group" style={{ marginTop: 5 }}>
                 <Checkbox checked={this.state.isAvailabilityLimit} onChange={e=>this.setState({isAvailabilityLimit:e.target.checked})}>Only show items in stock >= </Checkbox>
                 <input defaultValue="1" disabled={!this.state.isAvailabilityLimit} placeholder="1" className="" value={this.state.availabilityLimit} style={{ width: 120 }} onChange={e=>this.setState({availabilityLimit:e.target.value})}/>
               
                 </div>
                {/* <span className="input-field third-wrap">
                  <button onClick={this.searchParts} className="btn btn-info btn-icon" type="button" style={{ padding: '1.950rem 1.25rem' }}><i className="ti-search"></i></button>
                </span> */}

                {/*<Row className="row-advance" style={{ marginTop: '20px' }}>*/}
                {/*  <Col span={24} offset={0}>*/}
                {/*    <div className="text-right">*/}
                {/*      <Button shape='round' type="primary" onClick={this.showAdvanceDrawer} >*/}
                {/*        {pt('parts.Advanced')}*/}
                {/*      </Button>*/}
                {/*    </div>*/}
                {/*  </Col>*/}
                {/*</Row>*/}

              </form>
            </div>
          </div>
          <div className="page-content fade-in-up pt-0">
            <div className="ibox">
              <div className="ibox-body">
                {/* <h5 className="font-strong mb-4">{pt('parts.AllRecords')}</h5> */}
                {tagArr.length > 0 && advance ? <Row >
                  <Col span={24} >
                    {tagArr.map((tag, i) =>
                      <>
                        {i > 0 && <Tag color="gold" style={{ margin: '5px' }}>{advanceFilterdata.operator}</Tag>}
                        <Tag key={tag} closable
                          onClose={e => this.removeTag(tag)}
                          color="magenta"
                          style={{ margin: '5px' }}
                        >
                          {pt(`filter.${tag}`)} {pt(`filter.${advanceFilterdata[`${tag}_op`]}`)}  {advanceFilterdata[tag]}
                        </Tag></>)}

                  </Col>
                </Row> : null}
                <div id="plotter" className="mt-0">
                  <Plotter
                    // key={plotterKey}
                    loading={loading} data={parts}
                    getPartDetail={this.props.getPartDetail}
                    flushPartDetail={this.props.flushPartDetail}
                    loadingDetail={this.props.loadingDetail}
                    detail={this.props.detail}
                    showDrawer={this.showDrawer}
                    addToCart={this.addToCart}
                    current={current}
                    apiSort={this.apiSort}
                    onPaginationChange={this.onPaginationChange}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
        <SiderInfo
          visible={visible}
          onClose={this.onClose}
          addToCart={this.addToCartSider}
          data={info}
        />
        <Filters
          visible={this.state.advanceSearchShow}
          onClose={this.onAdvanceClose}
          submit={this.advanceSearch}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.parts.loading,
  parts: state.parts.data,
  str: state.parts.search,
  loadingDetail: state.parts.loadingDetail,
  detail: state.parts.detail,
  cart: state.parts.cart,
  advanceData: state.parts.advance
});

const mapDispatchToProps = dispatch => ({
  searchParts: (advanceData, payload, page, pageSize, order,isAvailabilityLimit,availabilityLimit) => dispatch(searchParts(advanceData, payload, page, pageSize,order,isAvailabilityLimit,availabilityLimit)),
  searchPartSTR: (payload) => dispatch(searchPartSTR(payload)),
  flushSearch: () => dispatch(flushSearch()),
  flushAdvanceSearch: () => dispatch(flushAdvanceSearch()),
  getPartDetail: (payload) => dispatch(getPartDetail(payload)),
  addToCart: (payload, type) => dispatch(addToCart(payload, type)),
  flushPartDetail: () => dispatch(flushPartDetail()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartsFinder);