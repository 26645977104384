import React from 'react';
import { connect } from 'react-redux';
import { doLogin } from '../Accounts/store/Actions';
import './login.css';
import {Link} from 'react-router-dom'
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Loader from '../Loader/Loader';
import T  from '../Translate';
import {pt}  from '../utils';
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    onFinish =(data)=>{
        console.log(this.formRef);
        console.log({data});
        this.props.doLogin(data)
    }

    onChangeRemember =(val)=>{
        console.log(this.formRef);
        this.formRef.current.setFieldsValue({ //Same here
            remember: val,
        });
    }


    render() {
        const { getFieldDecorator, getFieldValue } = this.formRef; 
        const {loading} =this.props;
        return (
            <>{loading && <Loader />}
            <div className="loginContainer">
                <div className="cover"></div>
                <div className="ibox login-content">
                    <div className="text-center">
                        <span className="auth-head-icon"><i className="la la-user"></i></span>
                    </div>
                    <div className="text-center logo"></div>
                    <Form
                        ref={this.formRef}
                        name="normal_login"
                        className="ibox-body" id="login-form"
                        initialValues={{
                            //remember: true,
                        }}
                    onFinish={this.onFinish}
                    > 
                    {/* <h4 className="font-strong text-center mb-5"><T k="login.login" /></h4> */}
                        <Form.Item 
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: pt("login.e.username"),
                                },
                            ]}
                        >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} className="mb-3" placeholder={pt("login.username")} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: pt("login.e.password"),
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                className="mb-3"
                                type="password"
                                placeholder={pt("login.password")}
                            />
                        </Form.Item>
                        
                        <Form.Item name="remember">
                            <div className="flexbox mb-5">
                                <span>
                                    {/* <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        onChange={this.onChangeRemember}
                                    />&nbsp;
                                    Remember */}
                                    </span>
                                <Link className="text-primary" to="/forgot-password">{pt("login.forgotpassword")}</Link>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div className="text-center mb-4">
                                <div className="text-center mb-4 pr-1">
                                    <button className="btn btn-main btn-rounded w-100">{pt("login.login")}</button>
                                </div>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    loading: state.accounts.loading,
    authenticated: state.accounts.authenticated,
});

const mapDispatchToProps = dispatch => ({
    doLogin: (payload) => dispatch(doLogin(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
