export const PARTS_LOADING = 'PARTS_LOADING';
export const SET_PARTS_LOADING = 'SET_PARTS_LOADING';
export const PARTS_ADVANCE_SEARCH_DATA = 'PARTS_ADVANCE_SEARCH_DATA';
export const FLUSH_PARTS_SEARCH = 'FLUSH_PARTS_SEARCH';
export const FLUSH_PARTS_ADVANCE_SEARCH = 'FLUSH_PARTS_ADVANCE_SEARCH';

export const PARTS_SEARCH_STR = 'PARTS_SEARCH_STR';
export const GET_PARTS_SUCCESS = 'GET_PARTS_SUCCESS';
export const GET_PARTS_ERROR = 'GET_PARTS_ERROR';

export const SET_PART_DETAIL_LOADING = 'SET_PART_DETAIL_LOADING';

export const GET_PART_DETAIL_SUCCESS = 'GET_PART_DETAIL_SUCCESS';
export const GET_PART_DETAIL_ERROR = 'GET_PART_DETAIL_ERROR';
export const FLUSH_PART_DETAIL = 'FLUSH_PART_DETAIL';

export const ADD_TO_CART_LOADING = 'ADD_TO_CART_LOADING';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'ADD_TO_CART_ERROR';

export const SET_QUOTATION_LOADING = 'SET_QUOTATION_LOADING';
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
export const GET_QUOTATION_ERROR = 'GET_QUOTATION_ERROR';

export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
export const GET_QUOTATIONS_ERROR = 'GET_QUOTATIONS_ERROR';

export const UPDATE_QUOTATION_SUCCESS = 'UPDATE_QUOTATION_SUCCESS';
export const UPDATE_QUOTATION_ERROR = 'UPDATE_QUOTATION_ERROR';

export const DELETE_QUOTATION_SUCCESS = 'DELETE_QUOTATION_SUCCESS';
export const DELETE_QUOTATION_ERROR = 'DELETE_QUOTATION_ERROR';

export const CREATE_QUOTATION_SUCCESS = 'CREATE_QUOTATION_SUCCESS';
export const CREATE_QUOTATION_ERROR = 'CREATE_QUOTATION_ERROR';
export const FLUSH_CART = 'FLUSH_CART';
export const FLUSH_QUOTATION = 'FLUSH_QUOTATION';
export const GET_PART_DETAIL_IMAGE_SUCCESS = 'GET_PART_DETAIL_IMAGE_SUCCESS';