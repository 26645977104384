export const AUTH_LOADING = 'AUTH_LOADING';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';


export const GET_ACCOUNTS_ORDERS_STATUS_SUCCESS = 'GET_ACCOUNTS_ORDERS_STATUS_SUCCESS';

export const ACCOUNTS_LOADING = 'ACCOUNTS_LOADING';
export const ACCOUNTS_FLUSH_DATA = 'ACCOUNTS_FLUSH_DATA';

export const GET_ACCOUNTS_ORDERS_SUCCESS = 'GET_ACCOUNTS_ORDERS_SUCCESS';
export const GET_ACCOUNTS_ORDERS_ERROR = 'GET_ACCOUNTS_ORDERS_ERROR';


export const GET_ACCOUNTS_ORDERS_DETAIL_SUCCESS = 'GET_ACCOUNTS_ORDERS_DETAIL_SUCCESS';
export const GET_ACCOUNTS_ORDERS_DETAIL_ERROR = 'GET_ACCOUNTS_ORDERS_DETAIL_ERROR';


export const GET_ACCOUNTS_ORDERS_CANCEL_SUCCESS = 'GET_ACCOUNTS_ORDERS_CANCEL_SUCCESS';
export const GET_ACCOUNTS_ORDERS_CANCEL_ERROR = 'GET_ACCOUNTS_ORDERS_CANCEL_ERROR';


