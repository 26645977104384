import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCart, addToCart } from './store/Actions';
import MiniCart from './MiniCart';
import Quantity from './Quantity'
import { Table, Space, Tooltip, Button,Row,Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { pt } from '../utils';
const { Title } = Typography;
class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            visible: false,
        }
    }
    componentDidMount() {
        this.props.getCart()
    }
    remove = (item) => {
        const {loading}=this.props;
        if(!loading){
            let cartdata = this.props.data.filter(datum => datum.id != item.id)
            this.props.addToCart(cartdata,'removed')
        }
       
    }

    callback = (qty, id) => {
        const {loading}=this.props;
        if (!loading && qty) {
            //console.log({id, qty});
            let { data } = this.props;
            data = data.map(datum => datum.id == id ? { id: id, quantity: qty } : { id: datum.id, quantity: datum.quantity })
            //console.log({ data });
            this.props.addToCart(data, 'updated')
        }

    }

    clearCart = () => {
        this.props.addToCart([], 'cleared')
    }

    render() {
        const el = {
            ellipsis: {
                showTitle: false,
            },
            render: val => (
                <Tooltip placement="topLeft" title={val}>
                    {val}
                </Tooltip>
            ),
        }
        const { mini, data } = this.props;
        const columns = [

            {
                title: pt('cart.SN'), dataIndex: 'SN.', key: 'SN.', width: '33px',
                render: (value, row, index) => !row.dummy ? index + 1 : '',
            },
            { title: pt('cart.Parts'), dataIndex: 'part_number', key: 'part_number', width: '70px', ...el },
            { title: pt('cart.Manufacturer'), dataIndex: 'manufacturer_part_number', key: 'manufacturer_part_number', width: '150px', ...el },
            { title: pt('cart.Description'), dataIndex: 'description', key: 'description', width: '100px', ...el },
            { title: pt('cart.Supplier'), dataIndex: 'supplier', key: 'supplier', width: '100px', ...el },
            { title: pt('cart.Availability'), dataIndex: 'availability', key: 'availability', width: '130px', ...el },
            {
                title: pt('cart.Quantity'), dataIndex: 'quantity', key: 'quantity', width: '150px',
                render: (d, row) => row.dummy && row.bold ? <span style={{ fontWeight: 'bold' }}>{d}</span> : row.dummy && !row.bold ? d : <Quantity loading={this.props.loading} key={row.id} data={row} callback={this.callback} />
            },
            { title: pt('cart.Price'), dataIndex: 'msrp', key: 'msrp', width: '70px', ...el },
            {
                title: pt('cart.Total'), dataIndex: 'total', key: 'total', width: '90px',
                render: (d, row) => row.dummy && row.bold ? <span style={{ fontWeight: 'bold' }}>{d}</span> : row.dummy && !row.bold ? d : (row.msrp * row.quantity).toFixed(2)
            },
            {
                title: '', dataIndex: 'action', key: 'action', width: '30px',
                render: (d, row) => row.dummy ? '' : <span onClick={e => this.remove(row)} className="d-inline-flex float-right text-primary"><i className="ti-trash mr-2"></i></span>
                    
            },
        ]
        let dataSource = data;
        let totalItems = 0;
        let subTotal = 0;
        let shipping = 0;
        let grandTotal = 0;
        let addtionalRows = [];
        if (data && data.length > 0) {
            data.map(item => {
                totalItems = totalItems + item.quantity;
                const { msrp, quantity } = item;
                //console.log(item);
                subTotal = subTotal + (parseFloat(msrp) * parseFloat(quantity));

            })
            grandTotal = subTotal - shipping;
            subTotal = parseFloat(subTotal).toFixed(2)
            grandTotal = parseFloat(grandTotal).toFixed(2)
            addtionalRows.push({ dummy: true, quantity: `${pt('cart.Subtotal')}  (${totalItems} ${pt('cart.items')} ) `, total: `$${subTotal}` })
            // addtionalRows.push({ dummy: true, quantity: `Shipping (Flat Rate) `, total: `$${shipping}` })
            addtionalRows.push({ dummy: true, bold: true, quantity: `${pt('cart.Total')}`, total: `$${grandTotal}` })
            dataSource = dataSource.concat(addtionalRows)
        }



        return (
            <>
                {
                    mini ? <MiniCart data={data} addToCart={this.props.addToCart} showCart={this.props.showCart} loading={this.props.loading}/> :

                        <div className="page-content fade-in-up pt-4">
                            <div className="ibox">
                                <div className="ibox-body">
                                    <div className="float-right font-strong mt-0 pt-4 mb-4">
                                        {data && data.length > 0 ?
                                        <Button danger={true} disabled={this.props.loading} shape="round" onClick={this.clearCart}>
                                            {pt('cart.clear')}
                                        </Button>
                                        : null}
                                    </div>
                                    <div>
                                        {/* <h5 className=" font-strong mb-4">{pt('cart.MyCarts')}</h5> */}
                                    </div>
                                    <div className="cart-container pt-3">
                                        {data && data.length > 0 ?
                                            <div className="table-responsive">
                                                <Table
                                                
                                                loading={this.props.loading} columns={columns} dataSource={dataSource} pagination={false} />

                                            </div> :
                                            <Row>
                                                <Col span={24}>
                                                <Title level={5}>{pt('cart.NoItems')}</Title>
                                                </Col>
                                            </Row>
                                            

                                        }

                                        <div className="row" style={{ marginTop: '20px' }}>

                                            <div className="col-sm-6 col-md-6 col-lg-6">
                                                <Link to="/" className="btn btn-primary btn-rounded"><i className="ti-angle-left mr-3"></i>{pt('cart.back')} </Link>
                                            </div>

                                            <div className="col-sm-6 col-md-6 col-lg-6 text-sm-right">
                                                {data && data.length > 0 ?
                                                    <Link to="/quotation/generate" className="btn btn-success btn-rounded">{pt('cart.GenerateQuotation')} <i className="ti-angle-right ml-2"></i></Link> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    loading: state.parts.loadingDetail,
    data: state.parts.cart,
});

const mapDispatchToProps = dispatch => ({
    addToCart: (payload,type) => dispatch(addToCart(payload,type)),
    getCart: () => dispatch(getCart()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Cart); 