import React, {useState} from 'react';
const Quantity = ({ data, callback,key,loading }) => {
    const [qty,setQty]=useState(data.quantity)

  
    const changeCounter = (payload) => {
        
        if (qty == 1 && payload == -1) {
            return;
        }
        if (qty > 0 || qty === 0 && payload == 1) {
            const newqty = qty + (payload);
            setQty(newqty)
            callback(newqty,data.id)
        }

    }

    const handleInput = (e) => {
        const { value } = e.target;
        if (!isNaN(value) && !loading) {
            if (value == 0 || value > 0) {
                setQty(value)
                callback(value,data.id)
            }
        }
    }

   



    return (
        <>
            <div className="value-button inc_value" style={{cursor:loading ? 'not-allowed':'pointer'}} onClick={e => changeCounter(-1)}>-</div>
            <input type="text"className="quantity-input" id="number" value={qty} 
            disabled={true}
            onChange={handleInput}
             />
            <div className="value-button" style={{cursor:loading ? 'not-allowed':'pointer'}} onClick={e => changeCounter(+1)} >+</div>
        </>
    )
}


export default Quantity;

