import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import moment from 'moment';
import { getQuotations, updateQuotation, deleteQuotation, getQuotation } from './store/Actions';
import { Table, Button, Badge, Tooltip } from 'antd';
import { ShoppingCartOutlined, EyeOutlined } from '@ant-design/icons';
import { pt } from '../utils';
import scrollToEl from '../scrollToEl'
class Quotations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      visible: false,
      currentPage: 1,
      pageSize: 10,
    }
  }

  onChnageSearch = (e) => {
    const { value } = e.target;
    this.setState({ search: value })
  }

  searchInObj = (obj, str) => {
    str = str.toLowerCase();
    let o = { ...obj }
    let found = false;
    o.id = this.formatId(o.id)
    o.created_date = moment(o.created_date).format('MM-DD-YYYY hh:mm A').toString()
    const arr = ['id', 'created_date', 'name', 'email', 'total','contact']
    for (const k of arr) {
      if (o && o[k] && o[k].toString().toLowerCase().includes(str)) {
        found = true;
        break;
      }
    }
    return found
  }

  totalCost = (obj) => {
    let total = 0;
    if (obj && obj.items && obj.items.length > 0) {
      obj.items.map(item => {
        total = parseFloat(parseInt(item.quantity) * parseFloat(item.msrp)) + total;
      })
    }
    return parseFloat(total).toFixed(2)
  }

  searchInData = () => {
    let data = this.props.data && this.props.data.results ? this.props.data.results : [];

    const { search } = this.state;
    data = data.map(datum => { return { ...datum, total: this.totalCost(datum) } })
    if (search) {
      data = data.filter(d => this.searchInObj(d, search))
    }

    return data;

  }

  componentDidMount() {
    this.props.getQuotations()
  }

  formatId = (id) => {
    let formattedId = '';
    if (id && this.props.user && this.props.user.pk) {
      const user_id = this.props.user.pk;
      console.log({user_id});
      for (let index = 0; index < 5-user_id.toString().length; index++) {
        formattedId =formattedId+'0';
      }
      formattedId = `${formattedId}${user_id}${id}`;
    }
    return formattedId;
  }



  onChangePagination = (currentPage, pageSize) => {
    
    scrollToEl('#quotationsss', -50, 1)
    this.setState({ currentPage, pageSize })
  }
  onKeyDown=(e)=>{
    if( e.keyCode == 13){
      e.preventDefault();
      
    }
  }

  render() {
    const el = {
      ellipsis: {
        showTitle: false,
      },
      render: val => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      ),
    }
    let { loading, } = this.props;
    let { currentPage, pageSize } = this.state;
    let data = this.searchInData();
    const { search } = this.state;
    const columns = [
      {
        title: '#', dataIndex: 'name', key: 'name', width: '20px', render: (n, r, index) => (((currentPage - 1) * pageSize) + index + 1),

      },
      {
        title: pt('Quote.Quote'), dataIndex: 'quote_id', key: 'id', width: '80px', 
        render: (id) => this.formatId(id),
        sorter: {
          compare: (a, b) => a.quote_id.localeCompare(b.quote_id),
        },
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: pt('Quote.Creation'), dataIndex: 'created_date', key: 'created_date', width: '100px', render: (d) => moment(d).format('MM-DD-YYYY hh:mm A').toString(),
        sorter: {
          compare: (a, b) => a.created_date.localeCompare(b.created_date),
        },
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: pt('Quote.Customer'), dataIndex: 'name', key: 'name', width:'100px',...el,
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
        },
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title: pt('Quote.Email'), dataIndex: 'email', key: 'email', width:'100px',...el,
        sorter: {
          compare: (a, b) => a.email.localeCompare(b.email),
        },
        sortDirections: ['ascend', 'descend', 'ascend']
      },
       {
        title: pt('Quote.Contact'), dataIndex: 'contact', key: 'contact', width:'70px',...el,
        sorter: {
          compare: (a, b) => a.contact.localeCompare(b.contact),
        },
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      {
        title:pt('Quote.Amount') , dataIndex: 'total', key: 'total', width: '100px', render: d => `$${d}`,
        sorter: {
          compare: (a, b) => a.total - (b.total),
        },
        sortDirections: ['ascend', 'descend', 'ascend']
      },
      // { title: 'Quotation Status', dataIndex: 'status', key: 'status',
      //   render:(status)=>(
      //   <> {
      //   status=='Completed' ?  <Badge count={status} className="site-badge"  style={{ backgroundColor: '#52c41a' }}/> : <Badge className="site-badge" style={{ backgroundColor: 'red' }} count={status}/>}</>)
      // },

      {
        title: '',
        dataIndex: 'id',
        key: 'action',
        width: '30px',
        render: (d, row) => (
          <>
            {/* <ShoppingCartOutlined
              // onClick={e=>props.addToCart(row)}
              style={{ fontSize: '20px', color: '#08c', marginRight: '5px' }}
            /> */}
            <Link to={`/quotations/${d}`} >
              <EyeOutlined style={{ fontSize: '20px', color: '#08c', }} />
            </Link>
          </>
        ),
      },
    ];
    const itemRender = (current, type, originalElement) => {
      if (type === 'prev') {
        return <a>{pt('Quote.Previous')}</a>;
      }
      if (type === 'next') {
        return <a>{pt('Quote.Next')}</a>;
      }
      return originalElement;
    }
    return (
      <>
        <div className="page-content fade-in-up pt-1">

          <div className="s003">
            <form>
              <div className="input-group">
                {/* <div className="input-field second-wrap"> */}
                  <input className="search-control border-right-0" onKeyDown={this.onKeyDown} value={search} id="search" type="text" placeholder={pt('Quote.search')} onChange={this.onChnageSearch} />
                {/* </div> */}
                <span className="input-group-append">
                  <button  className="btn-primary rounded-right" type="button" style={{ padding: '1.1rem 1.25rem' }}><i className="ti-search"></i></button>
                </span>
              </div>

            </form>
          </div>
        </div>
        <div className="page-content fade-in-up pt-0">
          <div className="ibox">
            <div className="ibox-body">

              <div className="cart-container-fluid">
                <div id="quotationsss" className="table-responsive pt-3">
                  <Table
                    showSorterTooltip={false}
                    bordered={true}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={{
                      onChange: this.onChangePagination,
                      position: ['topRight', 'bottomRight'],
                      size: pageSize,
                      showSizeChanger: false,
                      //itemRender: itemRender,
                      showTotal: (total, range) => `${pt('Quote.Showing')} ${range[0]} ${pt('Quote.to')} ${range[1]} ${pt('Quote.of')} ${total} ${pt('Quote.entries')}`,
                      defaultCurrent: 1
                    }}
                  />


                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  loading: state.parts.qLoading,
  data: state.parts.quotations,
  quotation: state.parts.quotation,
  user:state.accounts.user
});

const mapDispatchToProps = dispatch => ({
  getQuotations: () => dispatch(getQuotations()),
  getQuotation: (payload) => dispatch(getQuotation(payload)),
  updateQuotation: (id, payload) => dispatch(updateQuotation(id, payload)),
  deleteQuotation: (id) => dispatch(deleteQuotation(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quotations); 