import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getQuotation } from './store/Actions';
import { Link } from 'react-router-dom';
import { Table, Button, Form, Input, DatePicker, TimePicker,Card } from 'antd';
import {CaretLeftOutlined,DownloadOutlined } from '@ant-design/icons';
import Loader from '../Loader/Loader'
import CartItems from './CartItems';
import { pt } from '../utils';
const { TextArea } = Input;
class Quotation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null
        }
    }


    componentDidMount() {
        const { location, match } = this.props;
        const { params: { id } } = match;
        if (id) {
            this.setState({ id });
            this.props.getQuotation(id)
        }
        //console.log(location,match,id);
    }

    componentDidUpdate(prevProps) {
        const { location, match } = this.props;
        const { params: { id } } = match;
        if (id !== prevProps.match.params.id) {
            if (id) {
                this.setState({ id });
                this.props.getQuotation(id)
            }
        }
    }



    formatId = (id) => {
        let formattedId = '';
        if (id && this.props.user && this.props.user.pk) {
          const user_id = this.props.user.pk;
          console.log({user_id});
          for (let index = 0; index < 5-user_id.toString().length; index++) {
            formattedId =formattedId+'0';
          }
          formattedId = `${formattedId}${user_id}${id}`;
        }
        return formattedId;
      }



    render() {

        let { loading, user, qoute } = this.props;
        const { id } = this.state;
        const disabled = id ? true : false;


        return (
            <>{loading ? <Loader /> :

                <div className="page-content fade-in-up pt-4">
                    <Card>
                        {/* <Link to="/quotations" ><Button type="primary" icon={<CaretLeftOutlined  />}>Back to Quatatioin</Button></Link> */}
                        <Link className="btn btn-primary btn-rounded" to="/quotations" ><i className="ti-angle-left mr-3"></i>
                        {pt('generate.Back')}
                        </Link>
                    </Card>
                    {
                        qoute &&
                        <div className="ibox mb-2">
                            <div className="ibox-body">
                                <Form
                                    name="quoteform"
                                    className="form-horizontal" id="login-form"
                                    initialValues={{
                                        id: qoute && qoute.quote_id ? this.formatId(qoute.quote_id) : "",
                                        created_date: qoute && qoute.created_date ? moment(qoute.created_date).format('DD/MM/YYYY hh:mm:ss A').toString() : "",
                                        validity: qoute && qoute.validity ? moment(qoute.validity).format('DD/MM/YYYY hh:mm:ss A').toString() : '',
                                        name: qoute && qoute.name ? qoute.name : "",
                                        address: qoute && qoute.address ? qoute.address : "",
                                        contact: qoute && qoute.contact ? qoute.contact : "",
                                        email: qoute && qoute.email ? qoute.email : "",
                                        status: "Completed",
                                        dealer_name: user && user.username,
                                        dealer_email: user && user.email,
                                    }}

                                >

                                    <div className="cart-container pt-4">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.Quote')} labelCol={{ span: 8 }} name="id" labelAlign="left" colon={false}>
                                                        <Input placeholder="" disabled={true} />
                                                    </Form.Item>
                                                </div>
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.Creation')} labelCol={{ span: 8 }} name="created_date" labelAlign="left" colon={false}>
                                                        <Input placeholder="" disabled={true} />
                                                    </Form.Item>

                                                </div>
                                                <div className="form-group">
                                                    <Form.Item label={pt('generate.Valid')} labelCol={{ span: 8 }} name="validity" labelAlign="left" colon={false} >
                                                        <Input placeholder="" disabled={true} />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.dealer_name')} labelCol={{ span: 8 }} name="dealer_name" labelAlign="left" colon={false}>
                                                        <Input placeholder="" disabled={true} />
                                                    </Form.Item>

                                                </div>
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.dealer_email')} labelCol={{ span: 8 }} name="dealer_email" labelAlign="left" colon={false}>
                                                        <Input placeholder="" type="email" disabled={true} />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.Name')}  labelCol={{ span: 8 }} name="name" labelAlign="left" colon={false}  >
                                                        <Input placeholder="Your name" disabled={disabled} />
                                                    </Form.Item>

                                                </div>
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.Address')}  labelCol={{ span: 8 }} name="address" labelAlign="left" colon={false} >
                                                        <TextArea placeholder="Address" autoSize={{ minRows: 2, maxRows: 16 }} disabled={disabled} />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.Contact')} labelCol={{ span: 8 }} name="contact" labelAlign="left" colon={false} >
                                                        <Input placeholder="Address" type="text" disabled={disabled} />
                                                    </Form.Item>
                                                </div>

                                                <div className="form-group ">
                                                    <Form.Item label={pt('generate.EmailAddress')} labelCol={{ span: 8 }} name="email" labelAlign="left" colon={false}>
                                                        <Input placeholder="Email Address" disabled={disabled} />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12 text-sm-right">
                                            <Button type="primary" shape="round" icon={<DownloadOutlined />} size="small">
                                            {pt('generate.DownloadPDF')}
                                            </Button>
                                            &nbsp;
                                            {/* <Button type="primary" shape="round" icon={<DownloadOutlined />} size="large">
                                            Download Excel
                                            </Button> */}
                                               
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    }
                    {
                        qoute && <div className="ibox">
                            <div className="ibox-body">
                                <div className="cart-container pt-4">
                                    <div className="table-responsive">
                                        <CartItems data={qoute && qoute.items ? qoute.items : []} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                </div>
            }
            </>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.parts.qLoading,
    qoute: state.parts.quotation,
    data: state.parts.cart,
    user: state.accounts.user
});

const mapDispatchToProps = dispatch => ({
    getQuotation: (id) => dispatch(getQuotation(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Quotation); 