import React, { useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { pt } from '../utils';
import scrollToEl from '../scrollToEl'
import OutSideClick from '../Parts/OutSideClick';
const SideBar = (props) => {
    useEffect(()=>{
        console.log(props.location);
        scrollToEl('#root', -600, 1)
        if(props.visible){
           // props.outSideClickforMobile()
        }
        
    },[props.location.pathname])
    const { location: { pathname } } = props;
    const isActive = (url) => {
        return url == pathname ? 'active' : '';
    }
    const logout = (e) => {
        e.preventDefault();
        props.logout()
    }
    let className ='page-sidebar '
    if(window.innerWidth <576 && props.visible){
     className ='page-sidebar  shined'
    }

    const outSideClickforMobile =(e)=>{
        //props.outSideClickforMobile(e)
    }
    return (
        <OutSideClick callback={outSideClickforMobile}>
        <nav className={className} id="sidebar">
            <div id="sidebar-collapse" >
                <ul className="side-menu metismenu">
                    {/* <li className={isActive('/')}>
                        <Link to="/"><i className="sidebar-item-icon ti-home"></i>
                            <span className="nav-label">{pt('left.dashboard')}</span></Link>
                    </li> */}
                    <li className={isActive('/')}>
                        <Link to="/"><i className="sidebar-item-icon ti-search"></i>
                            <span className="nav-label">{pt('left.partfinder')}</span></Link>
                    </li>
                    <li className={isActive('/cart')}>
                        <Link to="/cart"><i className="sidebar-item-icon ti-shopping-cart"></i>
                            <span className="nav-label">{pt('left.Cart')}</span></Link>
                    </li>
                    <li className={isActive('/quotations')}>
                        <Link to="/quotations"><i className="sidebar-item-icon fa fa-dollar"></i>
                            <span className="nav-label">{pt('left.Quotations')}</span></Link>
                    </li>
                </ul>
                {/* <div className="sidebar-footer">
                    <Link to="/" onClick={logout}><i className="ti-power-off"></i></Link>
                </div> */}
            </div>
        </nav>
        </OutSideClick>
    )
}


export default withRouter(SideBar);