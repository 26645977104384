import * as ActionTypes from './ActionTypes';
const initialState = {
    loading: false,
    loadingDetail: undefined,
    search: '',
    error: undefined,
    data: undefined,
    detail: undefined,
    imagedata: undefined,
    cart: undefined,
    advance: undefined,
    qLoading: false,
    quotations: undefined,
    quotation: undefined

}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.SET_PARTS_LOADING:
            return { ...state, loading: payload }
        case ActionTypes.PARTS_SEARCH_STR:
            return { ...state, search: payload }
        case ActionTypes.PARTS_ADVANCE_SEARCH_DATA:
            return { ...state, advance: payload }
        case ActionTypes.FLUSH_PARTS_SEARCH:
                    return { ...state, search: '',data:undefined }
        case ActionTypes.FLUSH_PARTS_ADVANCE_SEARCH:
                    return { ...state, data:undefined }

        case ActionTypes.GET_PART_DETAIL_IMAGE_SUCCESS:
            return {
                ...state,
                imagedata: payload,
            }
        case ActionTypes.GET_PARTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: undefined,
                data: payload,
            }

        case ActionTypes.GET_PARTS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            }
        case ActionTypes.SET_PART_DETAIL_LOADING:
            return { ...state, loadingDetail: payload }

        case ActionTypes.GET_PART_DETAIL_SUCCESS:
            return {
                ...state,
                loadingDetail: false,
                error: undefined,
                detail: payload,
            }

        case ActionTypes.GET_PART_DETAIL_ERROR:
            return {
                ...state,
                loadingDetail: false,
                error: payload,
            }
        case ActionTypes.FLUSH_PART_DETAIL:
            return {
                ...state,
                loadingDetail: false,
                error: payload,
                detail: undefined
            }

        case ActionTypes.ADD_TO_CART_SUCCESS:

            return {
                ...state,
                cart: payload,
                error: undefined,
            }
        case ActionTypes.FLUSH_CART:

            return {
                ...state,
                cart: undefined,
                error: undefined,
            }
        case ActionTypes.FLUSH_QUOTATION:

            return {
                ...state,
                quotation: undefined,
                error: undefined,
            }

        case ActionTypes.SET_QUOTATION_LOADING:
            return { ...state, qLoading: payload }

        case ActionTypes.GET_QUOTATIONS_SUCCESS:

            return {
                ...state,
                quotations: payload,
                error: undefined,
            }
        case ActionTypes.GET_QUOTATION_SUCCESS:

            return {
                ...state,
                quotation: payload,
                error: undefined,
            }
        case ActionTypes.UPDATE_QUOTATION_SUCCESS:

            return {
                ...state,
                quotation: payload,
                error: undefined,
            }

        case ActionTypes.DELETE_QUOTATION_SUCCESS:

            return {
                ...state,
                quotation: undefined,
                error: undefined,
            }

        case ActionTypes.CREATE_QUOTATION_SUCCESS:

            return {
                ...state,
                quotation: payload,
                error: undefined,
            }

        default:
            return state;
    }
}
