import React from 'react';
import { connect } from 'react-redux';
import { resetpassword } from '../Accounts/store/Actions';
import './login.css';
import Loader from '../Loader/Loader';
import { Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {pt}  from '../utils';
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    onFinish = (data) => {
        console.log(this.formRef);
        const {match:{params:{uid,token}}}=this.props
        this.props.resetpassword({...data, uid, token}, this.props.history)
    }



    render() {

        return (
            <>{this.props.loading && <Loader />}
                <div className="cover"></div>
                <div className="ibox login-content">
                    <div className="text-center">
                        <span className="auth-head-icon"><i className="la la-key"></i></span>
                    </div>
                    <Form
                        ref={this.formRef}
                        name="normal_login"
                        className="ibox-body pt-0" id="forgot-form"
                        initialValues={{
                            //remember: true,
                        }}

                        onFinish={this.onFinish}
                    >

                    <h4 className="font-strong text-center mb-4">{pt('login.resetPassword')}</h4>
                        <p className="mb-4">{pt('login.newpassword')} .</p>
                        <div className="form-group mb-4">
                            <Form.Item
                                name="new_password1"
                                // label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message:pt('login.e.password') ,
                                        
                                    },
                                    { min: 8, message: pt('login.e.passwordminimum')},
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                name="new_password2"
                                //label="Confirm Password"
                                dependencies={['new_password1']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:pt('login.confirmpassword'),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('new_password1') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(pt('login.e.password2'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                        </div>
                        <Form.Item>
                            <div className="text-center">
                                <button className="btn btn-primary btn-rounded btn-block btn-air">{pt('login.SUBMIT')}</button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    loading: state.accounts.loading,
    authenticated: state.accounts.authenticated,
});

const mapDispatchToProps = dispatch => ({
    resetpassword: (payload,history) => dispatch(resetpassword(payload,history))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
