import React, { useEffect, useState, useRef } from 'react';
import userImg from '../../assets/img/users/admin-image.png';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Cart from '../Parts/Cart';
import { Badge } from 'antd';
import { getCart } from '../Parts/store/Actions'
import { ShoppingCartOutlined, EyeOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { Popconfirm, message } from 'antd';
import { Popover, Button } from 'antd';
import { pt } from '../utils';
const HeaderBar = (props) => {

    // console.log(props.location.pathname);
    const node = useRef();
    const [visible, setVisible] = useState(false)
    useEffect(() => {
        props.getCart();
    }, [])

    const logout = (e) => {
        e.preventDefault();
        props.logout()
    }
    const cancel=(e)=> {
    }
    const { pathname } = props.location;

    const pages = {
        '/': pt('top.partfinder'),
        '/parts-finder': pt('top.partfinder'),
        '/cart': pt('top.Cart'),
        '/quotations': pt('top.Quotations'),
        '/quotation/generate': pt('top.Generate'),
        ['/quotations/']: pt('top.QuotationDetail'),
    };
    // console.log(pages);

    const { user, CollapseSidebar, cart } = props;

    const outSideClickHandler = (e) => {
        if (node && node.current && node.current.contains(e.target)) {
            return;
        } else {
            setVisible(false)

        }
    }
    const showCart = (e, visibleMiniCart) => {
        if (e) { e.preventDefault(); }
        setVisible(visibleMiniCart)
    }
    useEffect(() => {
        setVisible(false)
    }, [props.location.pathname])
    let totalItems = 0;
    if (cart && cart.length > 0) { cart.map(item => { totalItems = totalItems + item.quantity; }) }
    document.title = pathname && pages[pathname] ? pages[pathname] + '  Mecaniste' : 'Quotation Detail Mecaniste'
    const menu = (
        <Menu onClick={e => props.lngChange(e.key)} selectedKeys={props.lng}>
            <Menu.Item key="en">
                <a >EN</a>
            </Menu.Item>
            <Menu.Item key="fr">
                <a >FR</a>
            </Menu.Item>
        </Menu>
    );

    const showNotificationonEmptyCart = () => {
        notification.info({
            message: pt('cart.NoItems'), top: 60, duration: 1

        });
    }

   
      
     
      
    return (
        <>
            <div className="page-brand">
                <Link to="/">
                    <span className="brand">Mecaniste</span>
                    <span className="brand-mini">MC</span>
                </Link>
            </div>
            <div className="flexbox flex-1" >
                <ul className="nav navbar-toolbar">
                    <li>
                        <a className="nav-link sidebar-toggler js-sidebar-toggler" onClick={CollapseSidebar}>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </a>
                    </li>
                    <li>

                        <span style={{ fontSize: '1.5rem' }}>
                            {pathname && pages[pathname] ? pages[pathname] : 'Quotation Detail'}
                        </span>
                    </li>
                </ul>

                <ul className="nav navbar-toolbar">
                    <li className="dropdown dropdown-inbox">
                        {totalItems > 0 ?

                            <Popover visible={visible} placement="bottomRight"
                                content={
                                    <Cart mini={true} showCart={outSideClickHandler} />
                                } title="" trigger="click"
                                overlayClassName="mini-cart-popup"
                                destroyTooltipOnHide={true}
                                overlayStyle={{ width: '360px', maxHeight: '350px', overflowY: 'hidden', zIndex: 10001, position: 'fixed' }}
                                overlayClassName="mini-cart-mobile"
                            >
                                <div className="nav-link dropdown-toggle"
                                    ref={node}
                                    onClick={e => showCart(e, !visible)}
                                >
                                    <Badge count={totalItems}><>
                                        <ShoppingCartOutlined fontSize="20px" color="red" className="cartIcon" />
                                        <span id="cartIcontext2">{pt('top.Cart')}</span></>
                                    </Badge>

                                </div>
                            </Popover> :
                            <div className="nav-link dropdown-toggle"
                                onClick={showNotificationonEmptyCart}
                            >
                                <Badge count={totalItems}><>
                                    <ShoppingCartOutlined fontSize="20px" color="red" className="cartIcon" />
                                    <span id="cartIcontext2">{pt('top.Cart')}</span></>
                                </Badge>

                            </div>}

                    </li>
                    <li>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {props.lng.toUpperCase()} <DownOutlined />
                            </a>
                        </Dropdown>

                    </li>
                    <li className="dropdown dropdown-user">
                        <a className="nav-link dropdown-toggle link" data-toggle="dropdown" aria-expanded="false">
                            <span>
                                {user ? <> {user.first_name ? `${user.first_name} ${user.last_name}` : user.username}</> : 'loading..'}
                            </span>
                            {/* <img src={userImg} alt="image" /> */}
                        </a>

                    </li>
                    <li>
                        <Popconfirm
                            title={pt('login.warning')}
                            onConfirm={logout}
                            onCancel={cancel}
                            okText={pt('login.Yes')}
                            cancelText={pt('login.Cancel')}
                            cancelButtonProps={{
                                className:'btn btn-circle ant-btn-dangerous logoutconfitm',
                                shape:'',
                                size:''
                            }}                           
                            placement="leftBottom"
                            overlayClassName="logout-warning"
                        >
                            <a className="nav-link quick-sidebar-toggler">
                                <i  className="ti-power-off" title="Logout"></i>
                            </a>
                        </Popconfirm>
                    </li>

                </ul>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    loading: state.accounts.loading,
    user: state.accounts.user,
    cart: state.parts.cart,
});

const mapDispatchToProps = dispatch => ({
    getCart: () => dispatch(getCart())
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderBar));

