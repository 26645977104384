import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { createQuotation } from './store/Actions';
import { Form, Input, DatePicker } from 'antd';
import CartItems from './CartItems';
import Loader from '../Loader/Loader';
import { pt } from '../utils';
import { CalendarOutlined } from '@ant-design/icons';
const { TextArea } = Input;
class GenerateQuotation extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();

    }

    onFinish = (data) => {
        data.validity = data.validity.format('YYYY-MM-DDThh:mm:ss').toString();
        data.items = this.props.data;
        data.status = 'Completed'
        data.quote_id = '0000001'
        this.props.createQuotation(data, this.props.history)
    }


    submit = (e) => {
        e.preventDefault();
        if (this.formRef && this.formRef.current) {
            this.formRef.current.submit()
        }
    }
    disabledDate = (current) => current < moment();




    render() {

        let { data, user, loading } = this.props;

        

        return (

            <div className="page-content fade-in-up pt-4">
                {loading && <Loader />}
                <div className="ibox mb-2">
                    <div className="ibox-body">
                        <Form
                            ref={this.formRef}
                            name="quoteform"
                            className="form-horizontal" id="login-form"
                            initialValues={{
                                id: "",
                                created_date: "",
                                validity: moment(moment(), 'DD/MM/YYYY'),
                                name: "",
                                address: "",
                                contact: "",
                                email: "",
                                status: "Completed",
                                dealer_name: user && user.username,
                                dealer_email: user && user.email,
                            }}
                            onFinish={this.onFinish}
                        >
{console.log(this.formRef)}
                            <div className="cart-container pt-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.Quote')} labelCol={{ span: 8 }} name="id" labelAlign="left" colon={false}>
                                                <Input placeholder="" disabled={true} />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.Creation')} labelCol={{ span: 8 }} name="created_date" labelAlign="left" colon={false}>
                                                <Input placeholder="" disabled={true} />
                                            </Form.Item>

                                        </div>
                                        <div className="form-group">
                                            <Form.Item label={pt('generate.Valid')} labelCol={{ span: 8 }} name="validity" labelAlign="left" colon={false} rules={[{
                                                required: true,
                                                message: pt('generate.e.Valid')
                                            }]} hasFeedback>
                                                <DatePicker
                                                    suffixIcon={<CalendarOutlined style={{marginRight:'15px'}}/> }
                                                //onChange={e=>console.log(e)}
                                                    style={{ width: '100%' }}
                                                    className="datepickerquote"
                                                    format="DD/MM/YYYY"
                                                    disabledDate={this.disabledDate}
                                                    labelCol={{ span: 16 }}
                                                    config={{
                                                        rules: [{
                                                            type: 'object',
                                                            required: true,
                                                            message: pt('generate.e.time'),
                                                        },
                                                        ]
                                                    }} />
                                            </Form.Item>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.dealer_name')} labelCol={{ span: 8 }} name="dealer_name" labelAlign="left" colon={false}>
                                                <Input placeholder="" disabled={true} />
                                            </Form.Item>

                                        </div>
                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.dealer_email')} labelCol={{ span: 8 }} name="dealer_email" labelAlign="left" colon={false}>
                                                <Input placeholder="" type="email" disabled={true} />
                                            </Form.Item>

                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.Name')} labelCol={{ span: 8 }} name="name" labelAlign="left" colon={false}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: pt('generate.e.Name'),

                                                    },
                                                    { min: 3, message: pt('generate.e.Nameminimum') },
                                                    // { min: 50, message: 'Name must have maximum 50 characters.' },
                                                ]}
                                                hasFeedback
                                            >
                                                <Input placeholder={pt('generate.Yourname')} maxLength={50} />
                                            </Form.Item>

                                        </div>
                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.Address')} labelCol={{ span: 8 }} name="address" labelAlign="left" colon={false}
                                                hasFeedback
                                                // rules={[{
                                                //     required: true,
                                                //
                                                //     message: pt('generate.e.Address')
                                                // }]}
                                                // required
                                                >
                                                <TextArea placeholder={pt('generate.Address')} maxLength={200} autoSize={{ minRows: 2, maxRows: 16 }} />
                                            </Form.Item>

                                        </div>
                                    </div>
                                    <div className="col-md-6 columnShift">
                                        <div className="form-group">
                                            <Form.Item label={pt('generate.Contact')} labelCol={{ span: 8 }} name="contact" labelAlign="left" colon={false}
                                                // rules={[
                                                //
                                                //     ({ getFieldValue }) => ({
                                                //         validator(rule, value) {
                                                //             if (!value ) {
                                                //                 return Promise.reject(pt('generate.e.contactrequired'));
                                                //             }
                                                //             else if (value && value.length !== 10) {
                                                //                 return Promise.reject(pt('generate.e.10digit'));
                                                //             }
                                                //             else if (value && !(/^\d{10}$/.test(value))) {
                                                //                 return Promise.reject(pt('generate.e.InvalidContact'));
                                                //
                                                //             } else {
                                                //                 return Promise.resolve();
                                                //             }
                                                //         }
                                                //     }),
                                                // ]}
                                                // hasFeedback
                                                // required
                                            >
                                                <Input placeholder={pt('generate.Contact')} maxLength={10} type="text" />
                                            </Form.Item>
                                        </div>

                                        <div className="form-group ">
                                            <Form.Item label={pt('generate.EmailAddress')} labelCol={{ span: 8 }} name="email" labelAlign="left" colon={false}
                                                // rules={[
                                                //
                                                //     ({ getFieldValue }) => ({
                                                //         validator(rule, value) {
                                                //             const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                                //
                                                //             if (!value ) {
                                                //                 return Promise.reject(pt('generate.e.validEmail'));
                                                //             }
                                                //             else if(!re.test(String(value).toLowerCase())){
                                                //                 return Promise.reject(pt('generate.e.Invalidemail'));
                                                //             }
                                                //             else {
                                                //                 return Promise.resolve();
                                                //             }
                                                //         }
                                                //     }),
                                                //
                                                //
                                                // ]}
                                                // hasFeedback
                                                // required
                                            >
                                                <Input placeholder={pt('generate.EmailAddress')} maxLength={50} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    </div>
                </div>
                <div className="ibox">
                    <div className="ibox-body">
                        <div className="cart-container pt-4">
                            <div className="table-responsive">
                                <CartItems data={data} />
                            </div>

                            <div className="row mt-4 pb-2">
                                <div className="col-lg-12 text-sm-right">

                                    <a
                                        onClick={this.submit}
                                        className="btn btn-success btn-rounded">
                                        {pt('generate.SubmitQuotation')}
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.parts.qLoading,
    data: state.parts.cart,
    user: state.accounts.user
});

const mapDispatchToProps = dispatch => ({
    createQuotation: (payload, history) => dispatch(createQuotation(payload, history)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenerateQuotation); 