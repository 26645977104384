import React from 'react';
import {Pagination as P} from 'antd';
import { pt } from '../utils';
const Pagination =({data, loading,onPaginationChange,current})=>{
    let total =data && data.total ? data.total : 0;
    const itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a>{pt('parts.Previous')}</a>;
        }
        if (type === 'next') {
            return <a>{pt('parts.Next')}</a>;
        }
        return originalElement;
    }
    return (
        <div className="row">
        <div className="pagination-parts" style={{opacity : loading ? .2 :1}}>
                    { data && data.data && data.data.length >0 &&
                        <P total={total}
                            defaultPageSize={data && data.perPage ? data.perPage : 20}
                            current={current}
                            onChange={onPaginationChange}
                            showSizeChanger={false}
                            //itemRender={itemRender}
                            showTotal={(total, range) =>
                                `${pt('parts.Showing')}  ${range[0]} ${pt('parts.to')}  ${range[1]} ${pt('parts.of')}  ${total} ${pt('parts.entries')} `}
                            defaultCurrent={1}
                        />
                    }
                </div>
                </div>
    )
}
export default Pagination
