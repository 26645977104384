import * as ActionTypes from './ActionTypes';
const initialState = {
    loading: false,
    authloading: true,
    orders: undefined,
    order: undefined,
    error: undefined,
    orderId: undefined,
    statuses: undefined,
    authenticated: undefined,
    token:null,
    user: undefined,

}

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.ACCOUNTS_LOADING:
            return { ...state, loading: payload }
        case ActionTypes.SET_AUTH_LOADING:
            return { ...state, authloading: payload }
        case ActionTypes.LOGIN_SUCCESS:
                    return { ...state, loading: false, authenticated:true , token:payload}


        case ActionTypes.LOGOUT_SUCCESS:
            localStorage.removeItem('mAuth');
            return {
                ...state,
                loading: false,
                authloading: false,
                orders: undefined,
                order: undefined,
                error: undefined,
                orderId: undefined,
                statuses: undefined,
                authenticated: false,
                token:null,
                user: undefined,
            }
        case ActionTypes.GET_USER_SUCCESS:
            return {
                ...state,
                authloading: false,
                error: undefined,
                authenticated: true,
                user: payload,
            }

        case ActionTypes.GET_ACCOUNTS_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload,
                error: undefined,
                loading: false,
                orderSyncTime: (new Date()).getTime(),
            }
        case ActionTypes.GET_ACCOUNTS_ORDERS_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }

        case ActionTypes.GET_ACCOUNTS_ORDERS_DETAIL_SUCCESS:
            return {
                ...state,
                order: payload,
                error: undefined,
                loading: false,
            }
        case ActionTypes.GET_ACCOUNTS_ORDERS_DETAIL_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }

        case ActionTypes.GET_ACCOUNTS_ORDERS_CANCEL_SUCCESS:
            return {
                ...state,
                order: payload,
                error: undefined,
                loading: false,
            }
        case ActionTypes.GET_ACCOUNTS_ORDERS_STATUS_SUCCESS:
            return {
                ...state,
                statuses: payload,
            }



        case ActionTypes.ACCOUNTS_FLUSH_DATA:
            return {
                ...state,
                loading: false,
                orders: undefined,
                error: undefined,
            }


        default:
            return state;
    }
}
