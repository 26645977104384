import React from 'react';
import { Link } from 'react-router-dom';
import OutSideClick from './OutSideClick';
import { Typography, Button } from 'antd';
import { pt } from '../utils';
const { Title } = Typography;
const MiniCart = ({ data, addToCart, showCart, loading }) => {
    const remove = (item) => {
        if(!loading){
            let cart = data.filter(datum => datum.id != item.id)
            addToCart(cart,'removed')
        }
       
    }
    const clearCart = () => {
        if(!loading){
        addToCart([],'cleared')
        }
    }



    return (
        <OutSideClick callback={showCart}>
            {data && data.length > 0 ?
                <div className="p3" style={{opacity:loading ? .3:1}}>
                    <div className="slimScrollDiv">
                        <div className="media-list media-list-divider scroller">
                            <div style={{ position: 'relative', overflow: 'auto', maxHeight: '240px', scrollbarWidth: 'thin' }} >
                                {
                                    data.map(item => <a className="media p-3" key={item.id}>
                                        <div className="media-body">
                                            <div className="media-heading" >{item.part_number}
                                                <span onClick={e => remove(item)} 
                                                    className="d-inline-flex float-right text-primary"><i className="ti-trash mr-2"></i></span>
                                                {/* <DeleteOutlined
                                                style={{ fontSize: '16px', color: 'red' }}
                                                onClick={e => remove(item)} className="d-inline-flex float-right text-primary" /> */}
                                            </div>
                                            <div className="font-13 text-muted mb-3">{item.description}</div>
                                            <div className="font-17 mt-1">
                                                <small className=" float-left">{pt('cart.Qty')}: {item.quantity}</small>
                                            </div>
                                            <div className="font-17 mt-1">
                                                <small className=" float-right">${item.msrp}</small>
                                            </div>
                                        </div>
                                    </a>)
                                }
                            </div>
                            <div className="mt-0" style={{padding:'20px'}}>
                                <div className="row" >

                                    <div className="col-lg-6">
                                        <Link to="/cart" className="btn btn-primary btn-rounded btn-block">
                                            
                                                {pt('cart.ViewCart')}
                                           
                                        </Link>
                                    </div>
                                    <div className="col-lg-6">
                                        <Button danger={true} disabled={loading} shape="round" onClick={clearCart} className="btn btn-block" >
                                            {pt('cart.clear')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <Title className="cart-item-0" level={5}>{pt('cart.NoItems')}</Title>
            }
        </OutSideClick>
    )
}


export default MiniCart;

