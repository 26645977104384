import React from 'react';
import { connect } from 'react-redux';
import { forgotPassword } from '../Accounts/store/Actions';
import './login.css'
import { Form, Input} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Loader from '../Loader/Loader'
class ForgetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
    }

    onFinish = (data) => {
        console.log(this.formRef);
        console.log({ data });
        this.props.forgotPassword(data)
    }

    

    render() {
        const validateMessages = {
            required: 'Email is required!',
            types: {
              email: 'Email is not a valid email!',
            },
          };
        
        return (
            <>{this.props.loading ? <Loader />: null}
            <div className="forgotContainer">
                <div className="cover"></div>
                <div className="ibox forgot-content">
                    <div className="text-center">
                        <span className="auth-head-icon"><i className="la la-key"></i></span>
                    </div>
                    <Form
                        ref={this.formRef}
                        name="normal_login"
                        className="ibox-body pt-0" id="forgot-form"
                        initialValues={{
                            //remember: true,
                        }}
                        validateMessages={validateMessages}
                        onFinish={this.onFinish}
                    >

                        <h4 className="font-strong text-center mb-4">FORGOT PASSWORD</h4>
                        <p className="mb-4">Enter your email address below and we'll send you password reset instructions.</p>
                        <div className="form-group mb-4">
                            <Form.Item
                                name="email"
                                rules={[{ type: 'email',required: true }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                            </Form.Item>

                        </div>
                        <Form.Item>
                            <div className="text-center mt-3 pb-3">
                                <button className="btn btn-primary btn-rounded w-100">Submit</button>
                            </div>
                        </Form.Item>
                    </Form>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    loading: state.accounts.loading,
    authenticated: state.accounts.authenticated,
});

const mapDispatchToProps = dispatch => ({
    forgotPassword: (payload) => dispatch(forgotPassword(payload))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgetPassword);
