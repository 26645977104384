import React from 'react';
import { Drawer, Button, Form, Card, Row, Col, Input, Select, Typography, Radio } from 'antd';
import { pt } from '../utils';
import { ShoppingCartOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Text, Link } = Typography;

class Filters extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            operator: 'OR',
            error:false
        }
    }

    onChange = (name, val) => {
        if (this.formRef && this.formRef.current) {
            this.formRef.current.setFieldsValue({[name]: val})
            this.setState({error:false})
            //console.log(this.formRef.current);
            // console.log(this.formRef.current.getFieldsError());
            // if(val){
            //     this.formRef.current.setFields([
            //         {
            //           name: `${name}_op`,
            //           errors: 'select val',
            //         },
            //      ]);
            // }
            // console.log(this.formRef.current.getFieldsError());
           
        }
    }




    onFinish = (values) => {
        const {operator} =this.state;
        const arr=['part_number','manufacturer_part_number','brand','description','msrp','availability','name']
        let hasValue =false;
        arr.map(a=>{
            if(!hasValue && values && values[a]){
                hasValue=true;
            }
        })
        if(hasValue){
            this.props.submit({...values, operator}, this.formRef.current)
        }
        else {
            this.setState({error:true})
        }
        
    };

    selectOptions =(lab)=>{
        const str =[
            { value: 'exact', label: '='},
            { value: 'start_with', label: pt(`filter.start_with`)},
            { value: 'end_with', label: pt(`filter.end_with`)},
            { value: 'contains', label: pt(`filter.contains`)},
        ];
        const nbr =[
            { value: 'exact', label: '='},
            { value: 'less', label: '<'},
            { value: 'greater', label: '>'},
            { value: 'lessEqual', label: '<='},
            { value: 'greaterEqual', label: '=>'},
        ]
      return   lab ? lab.type=='str' ? str :nbr :[]
    }
    onReset = () => {
        if (this.formRef && this.formRef.current) {
            this.formRef.current.resetFields();
        }
    };
    

    render() {

        const { operator,error } = this.state;
        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 14 },
            labelAlign: 'left'
        };
        const tailLayout = {
            wrapperCol: { offset: 10, span: 14 },
        };
       
        const labelArr=[
            {key:'part_number', type:'str'},
            {key:'manufacturer_part_number', type:'str'},
            {key:'brand', type:'str'},
            {key:'description', type:'str'},
            {key:'msrp', type:'nbr'},
            {key:'availability', type:'nbr'},
            {key:'name', type:'str'},
        ];
        const initialValues ={
            'part_number_op':'exact','manufacturer_part_number_op':'exact','brand_op':'exact','description_op':'exact','msrp_op':'exact','availability_op':'exact','name_op':'exact'
        }
        
        
        const Header = () => <Row className="header-part" >
            <Col span={12} xs={{ span: 12 }} >
                <Text style={{ fontWeight: 'bold', color: '#fff' }}>{pt('filter.AdvanceSearch')}</Text>
            </Col>
            <Col span={12} xs={{ span: 12 }}>
            
            <div className="float-right" style={{ marginTop: '10px' }} >
                    <Radio.Group
                        options={[
                            { label: 'AND', value: 'AND' },
                            { label: 'OR', value: 'OR' },
                        ]}
                        onChange={e => {this.onChange('operator', e.target.value);this.setState({operator:e.target.value})}}
                        value={operator}
                        //optionType="button"
                        //buttonStyle="solid"
                    />
                
          <CloseCircleOutlined
            title="Close"
            onClick={e => this.props.onClose()}
            style={{ color: ' #ffccc7', marginLeft: '15px', fontSize: '20px', }} />
        </div>

                

            </Col>

        </Row>

        return (
            <div className="site-drawer-render-in-current-wrapper">

                <Drawer
                    placement="right"
                    className="part-drawer"
                    width={550}
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    
                    style={{
                        //position: 'absolute',
                        zIndex: 999999,
                       // height: window.innerHeight,
                        scrollbarWidth: 'thin'
                        // maxHeight:'100vh',
                        // overflow:'hidden'
                    }}
                >

                    <Card ref={this.ref} className="part-siderinfo-header" title=''>
                        <Header />
                    </Card>
                    <div className="sideer-wrapper" 
                   // style={{ height: (window.innerHeight - 90), scrollbarWidth: 'thin' }}
                    >
                        <Card className="part-card ant-alert-info" style={{ marginBottom: '10px' }} title="">
                            <Form
                                ref={this.formRef}
                                {...layout}
                                name="basic"
                                initialValues={initialValues}
                                onFinish={this.onFinish}
                            >
                                {
                                   labelArr.map(lab=>
                                <Form.Item
                                    key={lab.key}
                                    name={lab.key}
                                    label={pt(`filter.${lab.key}`)}
                                >
                                    <Input.Group compact style={{ width: '100%' }}>
                                        <Form.Item name={`${lab.key}_op`} hidden={true} />
                                        <Select style={{width:'50%'}} dropdownStyle={{ zIndex: 999999, width:'50%' }} onChange={e => this.onChange(`${lab.key}_op`, e)} defaultValue="exact" >
                                            {
                                                this.selectOptions(lab).map(ops=><Option key={lab.key+ops.key} value={ops.value}>{ops.label}
                                                    </Option>)
                                            }
                                        </Select>
                                        <Input 
                                         rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value && lab.type =='nbr' && !(/^\d/.test(value)) ) {
                                                        return Promise.reject(pt('filter.e.invalid'));
                                                    } 
                                                    else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            }),
                                        ]}
                                        hasFeedback
                                        onChange={e=>this.onChange(lab.key, e.target.value)} style={{ width: '50%' }} type={lab.type =='str' ? 'text':'number'}/>
                                    </Input.Group>
                                </Form.Item>
                                ) 
                            }
                            {error ? pt('filter.error'):null}
                                <Form.Item {...tailLayout}>
                                    <Row>
                                        <Col span={12}>
                                            <Button type="primary" htmlType="submit" color="#4c6c8b">
                                            {pt('filter.Search')}
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                        <Button type="primary" className="float-right" danger={true} onClick={this.onReset}>
                                        {pt('filter.Reset')} 
                                        </Button>
                                        </Col>
                                    </Row>
                                   
                                   
                                </Form.Item>
                            </Form>
                        </Card>
                    </div>
                </Drawer>
            </div>
        );
    }
}

export default Filters;